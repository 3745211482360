import type { Action } from '@atlassian/react-sweet-state';
import { getAtlassianAccountWithRethrow } from '../services/index.tsx';
import type { State } from './types.tsx';

export const actions = {
	fetchAtlassianAccountData:
		({ source }: { source?: string } = {}): Action<State> =>
		async ({ setState, getState }) => {
			const { isFetching, wasFetchedOnce } = getState();
			if (isFetching || wasFetchedOnce) {
				return;
			}

			setState({ isFetching: true });

			try {
				const data = await getAtlassianAccountWithRethrow({ source });

				if (data !== null) {
					setState({ data });
				}
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (error: any) {
				setState({ fetchError: error });
			} finally {
				setState({ isFetching: false, wasFetchedOnce: true });
			}
		},
} as const;
