import { fg } from '@atlassian/jira-feature-gating';
import type { ProductName } from '@atlassian/jira-shared-types/src/product-name.tsx';
import type { ApplicationPermissions } from '@atlassian/jira-shared-types/src/tenant-context.tsx';

export const getProductName = (permissions: ApplicationPermissions): ProductName => {
	if (fg('jcs_master_flag')) {
		const {
			hasSoftwareAccess,
			hasServiceDeskAccess,
			hasCustomerServiceAccess,
			hasCoreAccess,
			hasProductDiscoveryAccess,
		} = permissions;

		// @ts-expect-error - TS2322 - Type 'string' is not assignable to type 'ProductName'.
		return (
			[
				{
					condition:
						hasSoftwareAccess &&
						!hasServiceDeskAccess &&
						!hasProductDiscoveryAccess &&
						!hasCustomerServiceAccess,
					value: 'software',
				},
				{
					condition:
						hasServiceDeskAccess &&
						!hasSoftwareAccess &&
						!hasProductDiscoveryAccess &&
						!hasCustomerServiceAccess,
					value: 'serviceDesk',
				},
				{
					condition:
						hasCoreAccess &&
						!hasSoftwareAccess &&
						!hasServiceDeskAccess &&
						!hasProductDiscoveryAccess &&
						!hasCustomerServiceAccess,
					value: 'core',
				},
				{
					condition:
						hasProductDiscoveryAccess &&
						!hasSoftwareAccess &&
						!hasServiceDeskAccess &&
						!hasCustomerServiceAccess,
					value: 'product-discovery',
				},
				{
					condition:
						hasCustomerServiceAccess &&
						!hasProductDiscoveryAccess &&
						!hasSoftwareAccess &&
						!hasServiceDeskAccess,
					value: 'customer-service',
				},
			].find((item) => item.condition) || { value: 'jira' }
		).value;
	}

	const { hasSoftwareAccess, hasServiceDeskAccess, hasCoreAccess, hasProductDiscoveryAccess } =
		permissions;

	// @ts-expect-error - TS2322 - Type 'string' is not assignable to type 'ProductName'.
	return (
		[
			{
				condition: hasSoftwareAccess && !hasServiceDeskAccess && !hasProductDiscoveryAccess,
				value: 'software',
			},
			{
				condition: hasServiceDeskAccess && !hasSoftwareAccess && !hasProductDiscoveryAccess,
				value: 'serviceDesk',
			},
			{
				condition:
					hasCoreAccess &&
					!hasSoftwareAccess &&
					!hasServiceDeskAccess &&
					!hasProductDiscoveryAccess,
				value: 'core',
			},
			{
				condition: hasProductDiscoveryAccess && !hasSoftwareAccess && !hasServiceDeskAccess,
				value: 'product-discovery',
			},
		].find((item) => item.condition) || { value: 'jira' }
	).value;
};
