import React, { useCallback, useMemo } from 'react';
import {
	CustomProductHome as AkCustomProductHomeDI,
	ProductHome as AkProductHomeDI,
} from '@atlaskit/atlassian-navigation';
import {
	JiraIcon,
	JiraLogo,
	JiraServiceManagementLogo,
	JiraServiceManagementIcon,
	JiraProductDiscoveryLogo,
	JiraProductDiscoveryIcon,
	AtlassianIcon,
	AtlassianLogo,
	type LogoProps,
} from '@atlaskit/logo';
import { Box } from '@atlaskit/primitives';
import { componentWithCondition } from '@atlassian/jira-feature-flagging-utils';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { testIdGenerate } from '@atlassian/jira-navigation-apps-common/src/utils/test-id.tsx';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav4/index.tsx';
import type { ProductName } from '@atlassian/jira-shared-types/src/product-name.tsx';
import { useProductName as useProductNameDI } from '@atlassian/jira-tenant-context-controller/src/components/product-name/index.tsx';
import { NavLogo } from '@atlassian/navigation-system';
import { useRouterActions as useRouterActionsDI } from '@atlassian/react-resource-router';
import { NAVIGATION_ITEM_ID } from '../../common/constants.tsx';
import { TopLevelErrorBoundary } from '../../common/ui/error-boundary/index.tsx';
import {
	useCustomLogo as useCustomLogoDI,
	useCustomIcon as useCustomIconDI,
} from '../../controllers/custom-logo/index.tsx';
import { useNavigationItemAnalytics as useNavigationItemAnalyticsDI } from '../../controllers/navigation-item-analytics/main.tsx';
import { useSiteTitle as useSiteTitleDI } from '../../controllers/site-title/index.tsx';
import messages from './messages.tsx';
import type { ProductHomeProps } from './types.tsx';

export const iconMap = {
	jira: { icon: JiraIcon, logo: JiraLogo },
	serviceDesk: { icon: JiraServiceManagementIcon, logo: JiraServiceManagementLogo },
	'product-discovery': {
		icon: JiraProductDiscoveryIcon,
		logo: JiraProductDiscoveryLogo,
	},
	'customer-service': {
		icon: AtlassianIcon,
		logo: AtlassianLogo,
	},
} as const;

const ProductHome = componentWithCondition(
	() => getWillShowNav4() && fg('jira_nav4_beta_drop_1'),
	ProductHomeNew,
	ProductHomeOld,
);

const ProductHomeWithErrorBoundary = (props: ProductHomeProps) => (
	<TopLevelErrorBoundary id={NAVIGATION_ITEM_ID.HOME}>
		<ProductHome {...props} />
	</TopLevelErrorBoundary>
);

export { ProductHomeWithErrorBoundary as ProductHome };

function ProductHomeOld({
	AkCustomProductHome = AkCustomProductHomeDI,
	AkProductHome = AkProductHomeDI,
	useCustomLogo = useCustomLogoDI,
	useNavigationItemAnalytics = useNavigationItemAnalyticsDI,
	useProductName = useProductNameDI,
	useRouterActions = useRouterActionsDI,
	useSiteTitle = useSiteTitleDI,
}: ProductHomeProps) {
	const testIdPrefix = testIdGenerate('product-home-');
	const logoUrl = useCustomLogo();
	const productName = useProductName();
	const siteTitleData = useSiteTitle();
	const siteTitle = (siteTitleData.shouldShow && siteTitleData.value) || undefined;

	const triggerAnalytics = useNavigationItemAnalytics({
		navigationItemId: NAVIGATION_ITEM_ID.LOGO,
	});

	const { formatMessage } = useIntl();

	const { push } = useRouterActions();
	const homeUrl = '/jira';
	const navigateToHome = useCallback(
		// @ts-expect-error - TS7006 - Parameter 'ev' implicitly has an 'any' type.
		(ev) => {
			triggerAnalytics();
			if (ev.ctrlKey || ev.metaKey) return;
			ev.preventDefault();
			push(homeUrl);
		},
		[triggerAnalytics, push, homeUrl],
	);

	if (logoUrl) {
		return (
			<AkCustomProductHome
				iconUrl={logoUrl}
				logoUrl={logoUrl}
				logoAlt={formatMessage(messages.customLogoAltText)}
				iconAlt={formatMessage(messages.customIconAltText)}
				href={homeUrl}
				onClick={navigateToHome}
				siteTitle={siteTitle}
				testId={testIdPrefix}
			/>
		);
	}

	if (fg('blu-5786-jswcloud-27436-primary-nav-msg')) {
		const { icon: Icon, logo: Logo } = getProductIconAndLogo(productName);
		const label = getLogoLabel(formatMessage, productName);
		return (
			<AkProductHome
				icon={(props) => <Icon {...props} label={label} />}
				logo={(props) => <Logo {...props} label={label} />}
				href={homeUrl}
				onClick={navigateToHome}
				siteTitle={siteTitle}
				testId={testIdPrefix}
			/>
		);
	}

	const { icon, logo } = getProductIconAndLogo(productName);
	return (
		<AkProductHome
			icon={icon}
			logo={logo}
			href={homeUrl}
			onClick={navigateToHome}
			siteTitle={siteTitle}
			testId={testIdPrefix}
		/>
	);
}

function ProductHomeNew({
	useCustomLogo = useCustomLogoDI,
	useCustomIcon = useCustomIconDI,
	useNavigationItemAnalytics = useNavigationItemAnalyticsDI,
	useProductName = useProductNameDI,
	useRouterActions = useRouterActionsDI,
}: ProductHomeProps) {
	const testIdPrefix = testIdGenerate('product-home-');
	const logoUrl = useCustomLogo();
	const iconUrl = useCustomIcon();
	const productName = useProductName();
	const triggerAnalytics = useNavigationItemAnalytics({
		navigationItemId: NAVIGATION_ITEM_ID.LOGO,
	});
	const { formatMessage } = useIntl();
	const { push } = useRouterActions();
	const homeUrl = '/jira';
	const navigateToHome = useCallback(
		// @ts-expect-error - TS7006 - Parameter 'ev' implicitly has an 'any' type.
		(ev) => {
			triggerAnalytics();
			if (ev.ctrlKey || ev.metaKey) return;
			ev.preventDefault();
			push(homeUrl);
		},
		[triggerAnalytics, push, homeUrl],
	);
	const { icon: ProductIcon, logo: ProductLogo } = getProductIconAndLogo(productName);

	const Logo = useMemo(
		() =>
			logoUrl
				? () => (
						<CustomImgNav4
							alt={formatMessage(messages.customLogoAltText)}
							src={logoUrl}
							testId={`${testIdPrefix}-logo`}
						/>
					)
				: (props: LogoProps) => <ProductLogo {...props} testId={`${testIdPrefix}-logo`} />,
		[logoUrl, ProductLogo, formatMessage, testIdPrefix],
	);
	const Icon = useMemo(
		() =>
			logoUrl && iconUrl
				? () => (
						<CustomImgNav4
							alt={formatMessage(messages.customIconAltText)}
							src={iconUrl}
							testId={`${testIdPrefix}-icon`}
						/>
					)
				: (props: LogoProps) => <ProductIcon {...props} testId={`${testIdPrefix}-icon`} />,
		[logoUrl, iconUrl, ProductIcon, formatMessage, testIdPrefix],
	);

	if (fg('blu-5786-jswcloud-27436-primary-nav-msg')) {
		const label = getLogoLabel(formatMessage, productName);
		return (
			<Box testId={`${testIdPrefix}-container`}>
				<NavLogo label={label} icon={Icon} logo={Logo} href={homeUrl} onClick={navigateToHome} />
			</Box>
		);
	}
	return (
		<Box testId={`${testIdPrefix}-container`}>
			<NavLogo label="Jira" icon={Icon} logo={Logo} href={homeUrl} onClick={navigateToHome} />
		</Box>
	);
}

// formatMessage is intentionally an any type due to absence of easily importable type, and because actual
// type definition contains any as well!
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function getLogoLabel(formatMessage: any, productName: ProductName): string {
	let label = '';
	if (productName === 'serviceDesk') {
		label = formatMessage(messages.jsmLogoLabel);
	} else if (productName === 'product-discovery') {
		label = formatMessage(messages.jpdLogoLabel);
	} else if (productName === 'customer-service') {
		label = formatMessage(messages.jcsLogoLabel);
	} else {
		label = formatMessage(messages.jiraLogoLabel);
	}
	return label;
}

function getProductIconAndLogo(productName: ProductName) {
	if (productName === 'core' || productName === 'software') {
		return iconMap.jira;
	}

	return iconMap[productName];
}

type CustomImgProps = {
	alt: string;
	src: string;
	testId: string;
};

function CustomImgNav4({ alt, src, testId }: CustomImgProps) {
	return <img src={src} alt={alt} data-testid={testId} />;
}
