/** @jsx jsx */
import { cssMap, cx, jsx } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { JiraProductDiscoveryIcon } from '@atlaskit/logo';
import { Flex } from '@atlaskit/primitives';

const styles = cssMap({
	JiraProductDiscoveryIconEnhancedStyle: {
		width: '28px',
		height: '28px',
		borderRadius: token('border.radius.100'),
		backgroundColor: token('color.background.neutral'),
	},
});

const JiraProductDiscoveryIconEnhanced = () => {
	return (
		<Flex
			xcss={cx(styles.JiraProductDiscoveryIconEnhancedStyle)}
			justifyContent="center"
			alignItems="center"
		>
			<JiraProductDiscoveryIcon appearance="brand" size="small" />
		</Flex>
	);
};

export default JiraProductDiscoveryIconEnhanced;
