import React from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import type { MenuInfoProps } from './types.tsx';

export const MenuInfo = ({
	imgAltText,
	imgHeight,
	imgSrc,
	text,
	useSmallImage = false,
	testId,
}: MenuInfoProps) => (
	<ViewWrapper data-testid={testId}>
		<ImgWrapper
			alt={imgAltText || ''}
			imgHeight={imgHeight}
			src={imgSrc}
			useSmallImage={useSmallImage}
		/>
		<TextWrapper>{text}</TextWrapper>
	</ViewWrapper>
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ViewWrapper = styled.div({
	margin: '0 auto',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	width: `${gridSize * 40}px`,
	display: 'flex',
	textAlign: 'center',
	flexDirection: 'column',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ImgWrapper = styled.img<{ useSmallImage?: boolean; imgHeight?: number }>({
	display: 'block',
	paddingTop: token('space.250'),
	paddingRight: 0,
	paddingBottom: token('space.200'),
	paddingLeft: 0,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	width: ({ useSmallImage }) => (useSmallImage ? `${gridSize * 12}px` : `${gridSize * 20}px`),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	height: ({ imgHeight }) => (imgHeight != null ? `${imgHeight}px` : 'auto'),
	margin: '0 auto',
	maxWidth: '100%',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const TextWrapper = styled.div({
	display: 'block',
	paddingTop: 0,
	paddingRight: token('space.250'),
	paddingBottom: token('space.250'),
	paddingLeft: token('space.250'),
});
