import React, { forwardRef } from 'react';
import { styled } from '@compiled/react';
import ChevronRightIcon from '@atlaskit/icon/utility/migration/chevron-right';
import { ButtonItem, type ButtonItemProps } from '@atlaskit/menu';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import {
	useAnalyticsEvents,
	fireUIAnalytics,
	type Attributes,
} from '@atlassian/jira-product-analytics-bridge';
import messages from './messages.tsx';

type Props = {
	testId?: string;
	itemPosition: number;
} & ButtonItemProps;

const TriggerButton = forwardRef(({ testId, itemPosition, ...rest }: Props, ref) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { formatMessage } = useIntl();
	return (
		<ButtonItem
			{...rest}
			// @ts-expect-error - TS2322 - Type 'MutableRefObject<unknown>' is not assignable to type 'LegacyRef<HTMLDivElement> | undefined'.
			ref={ref}
			onClick={(e) => {
				e.stopPropagation();
				const analyticsEvent = createAnalyticsEvent({
					action: 'clicked',
					actionSubject: 'navigationMenuItem',
				});
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				fireUIAnalytics(analyticsEvent, 'profileNavigationMenuItem', {
					menuItemId: 'theme',
					menuItemType: 'jira',
					position: itemPosition,
				} as Attributes);
			}}
			testId={testId}
			iconAfter={
				<ChevronRightIcon
					spacing="spacious"
					color={token('color.icon.subtle', colors.N100)}
					label=""
				/>
			}
		>
			<Text>{formatMessage(messages.text)}</Text>
		</ButtonItem>
	);
});

export default TriggerButton;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Text = styled.span({
	marginRight: token('space.200'),
});
