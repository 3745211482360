import defaultStorePlugin from 'store/plugins/defaults';
import expirePlugin from 'store/plugins/expire';
import storeEngine from 'store/src/store-engine';
import localStorage from 'store/storages/localStorage';
import memoryStorage from 'store/storages/memoryStorage';
import { plugins } from '../../utils.tsx';

export const makeLocalStore = () => storeEngine.createStore([localStorage, memoryStorage], plugins);

export const isLocalStorageAvailable = () =>
	typeof window !== 'undefined' && typeof window.localStorage !== 'undefined';

export const makeLocalExpirableStore = () =>
	storeEngine.createStore([localStorage, memoryStorage], [defaultStorePlugin, expirePlugin]);
