import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const FullHeightWrapper = styled.div({
	height: '100%',
	display: 'flex',
	alignItems: 'center',
	flexGrow: 1,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& > span': {
		flexGrow: 1,
		flexShrink: 1,
		flexBasis: '0%',
	},
});
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const FullHeightTextColorWrapper = styled.div({
	height: '100%',
	display: 'flex',
	alignItems: 'center',

	color: token('color.text'),
	flexGrow: 1,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& > span': {
		flexGrow: 1,
		flexShrink: 1,
		flexBasis: '0%',
	},
});
