import React, { useMemo } from 'react';
import DashboardIcon from '@atlaskit/icon/core/migration/dashboard';
import { HeadingItem } from '@atlaskit/menu';
import { DASHBOARDS_ITEM_TYPE } from '@atlassian/jira-favourite-change-provider/src/model/constants.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { getBeforeEntity } from '@atlassian/jira-navigation-apps-common/src/utils/index.tsx';
import { testIdConcat } from '@atlassian/jira-navigation-apps-common/src/utils/test-id.tsx';
import { dashboardViewRoute } from '@atlassian/jira-router-routes-dashboard-routes/src/dashboardViewRoute.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { DEFAULT_LOAD_COUNT_RECENT } from '../../../../common/constants.tsx';
import { FavoriteButton } from '../../../../common/ui/menu/favourite-button/main.tsx';
import { MenuItem } from '../../../../common/ui/menu/item/index.tsx';
import { useDashboardNavItems as useItems } from '../../../../controllers/items/index.tsx';
import messages from './messages.tsx';
import type { RecentContentProps } from './types.tsx';

export const RecentContent = ({ testIdPrefix }: RecentContentProps) => {
	const { formatMessage } = useIntl();

	const { recentItems, favoriteItems, changeFavorite: onChangeFavorite, changedItems } = useItems();

	const itemsToShow = useMemo(() => recentItems.slice(0, DEFAULT_LOAD_COUNT_RECENT), [recentItems]);

	const testId = testIdConcat(testIdPrefix, 'items-recent');

	return recentItems.length > 0 ? (
		<span data-testid={testId}>
			<HeadingItem testId={testIdConcat(testId, 'heading')}>
				{formatMessage(messages.recent)}
			</HeadingItem>
			{itemsToShow.map((item, idx) => {
				const { url, id, favourite: isFavourite, metadata, title } = item;
				const contextItem = changedItems[item.id.toString()];
				const isPending = contextItem && contextItem.pending === true ? contextItem.pending : false;
				const { id: beforeEntityId, type: beforeEntityType } = getBeforeEntity(favoriteItems);

				return (
					<MenuItem
						analytics={{
							actionSubjectId: 'dashboardsNavigationMenuItem',
							menuItemId: id,
							menuItemType: 'recent',
							position: idx,
							starred: Boolean(isFavourite),
						}}
						description={metadata}
						href={url}
						iconBefore={<DashboardIcon spacing="spacious" label="" />}
						iconAfter={
							<FavoriteButton
								beforeEntityId={beforeEntityId}
								beforeEntityType={beforeEntityType}
								id={String(id)}
								isFavorite={Boolean(isFavourite)}
								favoriteItemName={title}
								isPending={isPending}
								menuItemType="recent"
								onChangeFavorite={onChangeFavorite}
								type={DASHBOARDS_ITEM_TYPE}
							/>
						}
						key={id}
						testId={testIdConcat(testId, `item-${idx}`)}
						to={dashboardViewRoute}
						params={{ dashboardId: String(id) }}
						aria-label={
							fg('blu-6205_misc_nav3_a11y_fixes') ? `${title} ${metadata || ''}` : undefined
						}
					>
						{title}
					</MenuItem>
				);
			})}
		</span>
	) : null;
};
