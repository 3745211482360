import React, { useMemo } from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { AtlassianNavigation } from '@atlaskit/atlassian-navigation';
import {
	ROUTE_NAMES_GLOBAL_ISSUE_NAVIGATOR,
	ROUTE_NAMES_SOFTWARE_ISSUE_NAVIGATOR,
	ROUTE_NAMES_PROJECT_ISSUE_NAVIGATOR_CLASSIC,
	ROUTE_NAMES_SERVICEDESK_ISSUES,
	ROUTE_NAMES_PROJECT_ISSUE_NAVIGATOR_CORE,
	ROUTE_NAMES_ISSUE,
	ROUTE_NAMES_RAPIDBOARD_BOARD,
	ROUTE_NAMES_RAPIDBOARD_BACKLOG,
} from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { testIdGenerate } from '@atlassian/jira-navigation-apps-common/src/utils/index.tsx';
import { EditionAwareness } from '@atlassian/jira-navigation-apps-sidebar-edition-awareness/src/ui/index.tsx';
import { useCurrentRoute } from '@atlassian/jira-platform-router-utils/src/index.tsx';
import { useIsAnonymous } from '@atlassian/jira-tenant-context-controller/src/components/is-anonymous/index.tsx';
import messages from '../messages.tsx';
import { AppSwitcher } from '../app-switcher/main.tsx';
import { Create } from '../create/main.tsx';
import { Help } from '../help/main.tsx';
import { ProductHome } from '../product-home/main.tsx';
import { Profile } from '../profile/main.tsx';
import { Settings } from '../settings/index.tsx';
import { SignIn } from '../sign-in/main.tsx';
import { OpenOverflowMenuForPlansSpotlightObserver, Plans } from '../plans/index.tsx';
import { YourWorkDropdown } from '../your-work-dropdown/index.tsx';
import { Projects } from '../projects/index.tsx';
import { Filters } from '../filters/index.tsx';
import { Dashboards } from '../dashboards/index.tsx';
import { People } from '../people/index.tsx';
import { useShowAssets, Insight } from '../insight/index.tsx';
import { Roadmaps, useShowRoadmaps } from '../roadmaps/index.tsx';
import { Addons } from '../addons/index.tsx';
import { Search } from '../search/index.tsx';
import { useTheme } from '../../controllers/theme/main.tsx';
import { ConversationAssistant } from '../conversation-assistant/index.tsx';
import { Notifications } from '../notifications/main.tsx';

/*
    The DiscoverMore component is from experiment TBLZ-197, so rather than updating the Atlassian Navigation
    package we are extending the renderNotifications prop for now.
    If this experiment is productionised, we will move the code into the appropriate location.
*/
const RenderNotificationsWithConversationAssistant = () => {
	// eslint-disable-next-line react-hooks/rules-of-hooks
	const isAnonymous = fg('rovo_chat_hide_for_jira_anon_users') ? useIsAnonymous() : false;
	return (
		<>
			{!isAnonymous && <ConversationAssistant />}
			<Notifications />
		</>
	);
};

const SearchWithoutProps = () => <Search />;

const EditionAwarenessAndSearch = () => (
	<>
		<EditionAwarenessWrapper>
			<EditionAwareness />
		</EditionAwarenessWrapper>
		<SearchWithoutProps />
	</>
);

export const TopNavSync = () => {
	const theme = useTheme();
	const { formatMessage } = useIntl();
	const isAnonymous = useIsAnonymous();
	const showAssets = useShowAssets();
	const showRoadmaps = useShowRoadmaps();
	const route = useCurrentRoute();
	const PrimaryMenuItemsNav3 = useMemo(
		() =>
			[
				<OpenOverflowMenuForPlansSpotlightObserver key="arj-open-overflow-menu-for-plans-spotlight-observer" />,
				!isAnonymous && <YourWorkDropdown key="yourWorkDropdown" />,
				<Projects key="projects" />,
				<Filters key="filters" />,
				<Dashboards key="dashboards" />,
				<People key="people" />,
				<Plans key="plans" />,
				showRoadmaps && <Roadmaps key="roadmaps" />,
				showAssets && <Insight key="insight" />,
				<Addons key="addons" />,
			].filter(Boolean),
		[isAnonymous, showAssets, showRoadmaps],
	);

	const ssrPlaceholderEnabledRoutes = [
		...(fg('add_ssr_placeholder_replacements_to_nin_and_nav')
			? [
					ROUTE_NAMES_GLOBAL_ISSUE_NAVIGATOR,
					ROUTE_NAMES_SOFTWARE_ISSUE_NAVIGATOR,
					ROUTE_NAMES_PROJECT_ISSUE_NAVIGATOR_CLASSIC,
					ROUTE_NAMES_SERVICEDESK_ISSUES,
					ROUTE_NAMES_PROJECT_ISSUE_NAVIGATOR_CORE,
				]
			: []),
		...(fg('add_ssr_placeholder_replacements_to_nav_for_iv') ? [ROUTE_NAMES_ISSUE] : []),
		...(fg('add_nav_ssr_placeholder_for_board_and_backlog')
			? [ROUTE_NAMES_RAPIDBOARD_BOARD, ROUTE_NAMES_RAPIDBOARD_BACKLOG]
			: []),
	];

	return (
		<AtlassianNavigation
			primaryItems={PrimaryMenuItemsNav3}
			label={formatMessage(messages.topNav)}
			moreLabel={formatMessage(messages.more)}
			renderAppSwitcher={AppSwitcher}
			renderCreate={Create}
			renderHelp={Help}
			renderNotifications={RenderNotificationsWithConversationAssistant}
			renderProductHome={ProductHome}
			renderProfile={Profile}
			renderSearch={EditionAwarenessAndSearch}
			renderSettings={Settings}
			renderSignIn={SignIn}
			theme={theme}
			testId={testIdGenerate(null)}
			isServer={__SERVER__}
			isSSRPlaceholderEnabled={ssrPlaceholderEnabledRoutes.includes(route.name)}
		/>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const EditionAwarenessWrapper = styled.div({
	marginRight: token('space.200'),

	// On screen widths < 680px, hide the Edition Awareness component
	'@media (max-width: 680px)': {
		display: 'none',
	},
});
