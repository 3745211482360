import React, { useCallback, useState, useMemo } from 'react';
import { styled } from '@compiled/react';
import OriginTracing from '@atlassiansox/origin-tracing';
import Button from '@atlaskit/button';
import type { Placement } from '@atlaskit/popper';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { useCommerceExperienceDrawer } from '@atlassian/jira-commerce-experience-drawer/src/ui/index.tsx';
import { useCommerceExperienceEmbedExperiment } from '@atlassian/jira-commerce-experience-drawer/src/ui/utils.tsx';

import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import PremiumTrial from '@atlassian/jira-illustrations/src/ui/adg4/jira/spots/other/components/premium-trial/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { JiraPopup as Popup } from '@atlassian/jira-popup/src/ui/jira-popup.tsx';
import {
	ContextualAnalyticsData,
	FireScreenAnalytics,
	fireUIAnalytics,
	SCREEN,
	useAnalyticsEvents,
} from '@atlassian/jira-product-analytics-bridge';
import { useFetchOnboardingReverseTrial } from '@atlassian/jira-reverse-trial-utils/src/controllers/use-fetch-onboarding-reverse-trial/index.tsx';
import { useForceOpenPremiumPopupOnDay3 } from '@atlassian/jira-reverse-trial/src/controllers/use-force-open-premium-popup-on-day-3/index.tsx';
import { ReverseTrialExperimentPopupContent } from '@atlassian/jira-reverse-trial/src/ui/reverse-trial-popup/index.tsx';
import { STANDARD_EDITION } from '@atlassian/jira-shared-types/src/edition.tsx';
import { EDITION_AWARENESS, PACKAGE_NAME } from '../../../../common/constants.tsx';
import {
	PREMIUM_FEATURES_COHORT_NOT_ENROLLED,
	firePremiumFeaturesExperimentExposure,
} from '../dropdown/utils.tsx';
import messages from './messages.tsx';
import type { BillingDetailsPopupProps } from './types.tsx';

const ReverseTrialExperimentErrorBoundary = ({ children }: { children: React.ReactNode }) => (
	<JSErrorBoundary
		id={EDITION_AWARENESS}
		packageName={PACKAGE_NAME}
		fallback="unmount"
		teamName="growth-tako"
	>
		{children}
	</JSErrorBoundary>
);

type PopupInnerProps = {
	url: string;
	defaultPopupInner: React.ReactElement;
	onFireUiAnalyticsEvent: (
		action: string,
		actionSubject: string,
		actionSubjectId: string,
		attributes?: Record<string, unknown>,
	) => void;
};

const PopupInner = ({ url, defaultPopupInner, onFireUiAnalyticsEvent }: PopupInnerProps) => {
	const { premiumTrialOnSignup, isReady } = useFetchOnboardingReverseTrial();

	if (!isReady) {
		return null;
	}

	if (isReady && premiumTrialOnSignup) {
		return (
			<ReverseTrialExperimentPopupContent
				billingPageUrl={url}
				onFireUiAnalyticsEvent={onFireUiAnalyticsEvent}
			/>
		);
	}

	return defaultPopupInner;
};

const ReverseTrialExperimentForceOpen = ({
	onPopupToggle,
}: {
	onPopupToggle: (show: boolean, autoOpened?: boolean) => void;
}) => {
	const { premiumTrialOnSignup } = useFetchOnboardingReverseTrial();
	useForceOpenPremiumPopupOnDay3({
		shouldRun: premiumTrialOnSignup,
		onPopupToggle,
	});
	return null;
};

const PopupBackground = ({ defaultBackground }: { defaultBackground: React.ReactElement }) => {
	const { premiumTrialOnSignup, isReady } = useFetchOnboardingReverseTrial();
	if (!isReady || premiumTrialOnSignup) {
		return null;
	}

	return defaultBackground;
};

export const BillingDetailsPopup = (props: BillingDetailsPopupProps) => {
	const {
		initialOpenState = false,
		edition,
		addBillingDetailsUrl,
		analyticsAttributes,
		inGracePeriod,
		productKey,
		children,
		billingSourceSystem,
	} = props;

	const { formatMessage } = useIntl();
	const [isOpen, setIsOpen] = useState(initialOpenState);
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { premiumTrialOnSignup, isReady } = useFetchOnboardingReverseTrial();

	const {
		fireExposure: fireCommerceEmbedExperimentExposure,
		isEligible: isEligibleForCommerceEmbedExperiment,
		isVariation: isVariationInCommerceEmbedExperiment,
	} = useCommerceExperienceEmbedExperiment({
		isCcpBillingSourceSystem: billingSourceSystem === 'CCP',
		isJstoTarget: false,
	});

	const [_, { openDrawer: openCommerceEmbed }] = useCommerceExperienceDrawer();

	const popupTitle = formatMessage(
		edition === STANDARD_EDITION
			? messages.addPaymentDetailsPopupStandardTitle
			: messages.addPaymentDetailsPopupPremiumTitle,
	);
	const popupContent = formatMessage(
		edition === STANDARD_EDITION
			? messages.addPaymentDetailsPopupStandardContent
			: messages.addPaymentDetailsPopupPremiumContent,
	);

	const atlOrigin = useMemo(() => new OriginTracing({ product: 'jira' }), []);
	const url = atlOrigin.addToUrl(addBillingDetailsUrl);

	const fireUIAnalyticsEvent = useCallback(
		(
			action: string,
			actionSubject: string,
			actionSubjectId: string,
			attributes: Record<string, unknown> = {},
		) => {
			fireUIAnalytics(
				createAnalyticsEvent({
					action,
					actionSubject,
				}),
				actionSubjectId,
				{
					...analyticsAttributes,
					...attributes,
				},
			);
		},
		[analyticsAttributes, createAnalyticsEvent],
	);

	const onClickButton = useCallback(() => {
		fireUIAnalyticsEvent('clicked', 'button', 'paymentDetailsPopupItem', {
			...atlOrigin.toAnalyticsAttributes({ hasGeneratedId: true }),
		});
		setIsOpen(false);

		if (isEligibleForCommerceEmbedExperiment) {
			fireCommerceEmbedExperimentExposure();

			if (isVariationInCommerceEmbedExperiment) {
				openCommerceEmbed(url);
			}
		}
	}, [
		atlOrigin,
		fireUIAnalyticsEvent,
		fireCommerceEmbedExperimentExposure,
		isEligibleForCommerceEmbedExperiment,
		isVariationInCommerceEmbedExperiment,
		openCommerceEmbed,
		url,
	]);

	const onPopupToggle = useCallback(
		// autoOpened is temporary for monitoring jsw_reverse_trials_feature_gate
		(show: boolean, autoOpened?: boolean) => {
			const { cohort: premiumFeaturesCohort } = firePremiumFeaturesExperimentExposure({
				productKey,
				edition,
				inGracePeriod,
				createAnalyticsEvent,
			});

			fireUIAnalyticsEvent('toggled', 'popup', 'editionAwarenessTrialPillPopup', {
				show,
				...(premiumFeaturesCohort !== PREMIUM_FEATURES_COHORT_NOT_ENROLLED && {
					premiumFeaturesCohort,
				}),
				...(autoOpened !== undefined && { autoOpened }),
			});

			setIsOpen(show);
		},
		[createAnalyticsEvent, edition, fireUIAnalyticsEvent, inGracePeriod, productKey],
	);

	const onTriggerClick = useCallback(() => {
		onPopupToggle(!isOpen);
	}, [onPopupToggle, isOpen]);

	const getPopupPlacement = (): Placement => {
		if (isReady && premiumTrialOnSignup) {
			return 'bottom-end';
		}

		return 'bottom-start';
	};

	const defaultPopupInner = (
		<>
			<Box as="h3" xcss={popupHeadingStyles}>
				{popupTitle}
			</Box>
			<Box as="p" xcss={popupContentStyles}>
				{popupContent}
			</Box>
			{isVariationInCommerceEmbedExperiment ? (
				<Button appearance="primary" onClick={onClickButton}>
					{formatMessage(messages.addPaymentDetailsPopupButton)}
				</Button>
			) : (
				<Button appearance="primary" href={url} target="_blank" onClick={onClickButton}>
					{formatMessage(messages.addPaymentDetailsPopupButton)}
				</Button>
			)}
		</>
	);

	const defaultBackground = (
		<Background>
			<PremiumTrial alt="premium-trial-banner" />
		</Background>
	);

	const adjustAnalyticsAttributes = () => {
		// Checking for undefined specifically because if the user is not targeted, the value will be undefined
		if (isReady && premiumTrialOnSignup !== undefined) {
			return {
				...analyticsAttributes,
				isPremiumTrialOnSignup: premiumTrialOnSignup,
			};
		}

		return analyticsAttributes;
	};

	return (
		<>
			{fg('jsw_reverse_trials_feature_gate') && (
				<ReverseTrialExperimentErrorBoundary>
					<ReverseTrialExperimentForceOpen onPopupToggle={onPopupToggle} />
				</ReverseTrialExperimentErrorBoundary>
			)}
			<Popup
				messageId="navigation-apps-sidebar-edition-awareness.ui.trial-pill.trial-pill-button.billing-details-popup.popup.jira-popup"
				messageType="transactional"
				isOpen={isOpen}
				onClose={() => onPopupToggle(false)}
				content={() => (
					<ContextualAnalyticsData sourceName="trialPillPopup" sourceType={SCREEN}>
						<FireScreenAnalytics attributes={adjustAnalyticsAttributes()} />
						{fg('jsw_reverse_trials_feature_gate') ? (
							<ReverseTrialExperimentErrorBoundary>
								<PopupBackground defaultBackground={defaultBackground} />
							</ReverseTrialExperimentErrorBoundary>
						) : (
							defaultBackground
						)}
						<PopupWrapper>
							{fg('jsw_reverse_trials_feature_gate') ? (
								<ReverseTrialExperimentErrorBoundary>
									<PopupInner
										url={url}
										defaultPopupInner={defaultPopupInner}
										onFireUiAnalyticsEvent={fireUIAnalyticsEvent}
									/>
								</ReverseTrialExperimentErrorBoundary>
							) : (
								defaultPopupInner
							)}
						</PopupWrapper>
					</ContextualAnalyticsData>
				)}
				placement={getPopupPlacement()}
				trigger={(triggerProps) => children({ triggerProps, onClick: onTriggerClick })}
			/>
		</>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const PopupWrapper = styled.div({
	width: '270px',
	paddingTop: token('space.250'),
	paddingRight: token('space.250'),
	paddingBottom: token('space.250'),
	paddingLeft: token('space.250'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Background = styled.div({
	display: 'flex',
	justifyContent: 'center',
	// eslint-disable-next-line @atlaskit/design-system/ensure-design-token-usage
	background: 'linear-gradient(45deg, #388BFF 0%, #0C66E4 51.23%, #8270DB 98.15%)',
});

const popupHeadingStyles = xcss({
	color: 'color.text',
	font: 'font.heading.medium',
});

const popupContentStyles = xcss({
	marginTop: 'space.100',
	marginBottom: 'space.150',
	color: 'color.text.subtle',
	font: 'font.body.small',
});
