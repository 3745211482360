import { useEffect } from 'react';
import { ONBOARDING_STARTED_TIMESTAMP } from '@atlassian/jira-onboarding-core/src/constants.tsx';
import { localStorageProvider } from '@atlassian/jira-onboarding-core/src/utils/index.tsx';
import { expValEquals } from '@atlassian/jira-feature-experiments';

type Props = {
	shouldRun?: boolean;
	onPopupToggle: (show: boolean, autoShown?: boolean) => void;
};

export const useForceOpenPremiumPopupOnDay3 = ({ shouldRun, onPopupToggle }: Props) => {
	useEffect(() => {
		if (shouldRun) {
			const onboardingStartedTimestamp = Number(
				localStorageProvider.get(ONBOARDING_STARTED_TIMESTAMP),
			);

			if (onboardingStartedTimestamp) {
				const threeDays = 3 * 24 * 60 * 60 * 1000;
				// If the user has onboarded 3 or more days ago, force open the premium popup
				if (Date.now() - onboardingStartedTimestamp > threeDays) {
					if (!expValEquals('jsw_ea_popover_open_vs_close', 'cohort', 'variation')) {
						onPopupToggle(true, true);
					}
					localStorageProvider.remove(ONBOARDING_STARTED_TIMESTAMP);
				}
			}
		}
	}, [shouldRun, onPopupToggle]);
};
