import type { Environment } from 'react-relay';
import { fetchQuery, graphql } from 'relay-runtime';
import type { CanonicalId } from '@atlassian/jira-common-constants/src/project-types.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import type { commerceSharedApiEditionAwarenessTrialQuery } from '@atlassian/jira-relay/src/__generated__/commerceSharedApiEditionAwarenessTrialQuery.graphql';
import { PRODUCT_DISCOVERY } from '@atlassian/jira-shared-types/src/application-key.tsx';
import type { BillingInformation } from '../types.tsx';

export const Query = graphql`
	query commerceSharedApiEditionAwarenessTrialQuery(
		$cloudId: ID!
		$productKey: String!
		$isTrialCountdownFgExpEnabled: Boolean!
		$isInGracePeriodFixFgEnabled: Boolean!
	) {
		tenantContexts(cloudIds: [$cloudId]) {
			entitlementInfo(hamsProductKey: $productKey) {
				entitlement(where: { AND: [{ inTrialOrPreDunning: true }] }) {
					subscription {
						accountDetails {
							invoiceGroup {
								invoiceable @required(action: THROW)
								... on CcpInvoiceGroup {
									id
								}
							}
						}
						pricingPlan {
							primaryCycle {
								interval @required(action: THROW)
							}
							type @required(action: THROW)
						}
					}
					transactionAccount {
						key @required(action: THROW)
						isCurrentUserBillingAdmin @required(action: THROW)
						isManagedByPartner @required(action: THROW)
					}
					... on HamsEntitlement {
						overriddenEdition
					}
					offering @include(if: $isTrialCountdownFgExpEnabled) {
						... on CcpOffering {
							trial {
								lengthDays
							}
						}
					}
					preDunning @include(if: $isInGracePeriodFixFgEnabled) {
						status
					}
				}
			}
		}
	}
`;

export const getBillingInformationGraphql = async (
	cloudId: string,
	productKey: CanonicalId,
	relayEnvironment: Environment,
): Promise<BillingInformation> => {
	// The product key as used by Commerce.
	// JPD product key as used by Commerce does not include .ondemand
	const commerceProductKey =
		productKey === PRODUCT_DISCOVERY ? productKey : `${productKey}.ondemand`;

	const response = await fetchQuery<commerceSharedApiEditionAwarenessTrialQuery>(
		relayEnvironment,
		Query,
		{
			cloudId,
			productKey: commerceProductKey,
			isTrialCountdownFgExpEnabled: fg('jira_edition_awareness_trial_countdown_gate'),
			isInGracePeriodFixFgEnabled: fg('jira_edition_awareness_isgraceperiod_check_fix'),
		},
		{
			fetchPolicy: 'store-or-network',
		},
	).toPromise();

	const entitlementInfo =
		response?.tenantContexts && response.tenantContexts.length > 0
			? response.tenantContexts[0]?.entitlementInfo
			: undefined;

	const entitlement = entitlementInfo?.entitlement;
	const subscription = entitlement?.subscription;
	const invoiceGroup = subscription?.accountDetails?.invoiceGroup;
	const pricingPlan = subscription?.pricingPlan;
	const transactionAccount = entitlement?.transactionAccount;

	const offering = entitlement?.offering;

	return {
		notInTrialOrPredunning: entitlement === null,
		invoiceable: invoiceGroup?.invoiceable ?? null,
		transactionAccountId: transactionAccount?.key ?? null,
		invoiceGroupId: invoiceGroup?.id ?? null,
		isBillingAdmin: transactionAccount?.isCurrentUserBillingAdmin ?? null,

		partnerManaged: transactionAccount?.isManagedByPartner ?? null,
		billingInterval: pricingPlan?.primaryCycle?.interval ?? null,
		pricingPlanType: pricingPlan?.type ?? null,
		overriddenEdition: entitlement?.overriddenEdition ?? null,
		trialLengthDays: offering?.trial?.lengthDays ?? null,
		preDunningStatus: entitlement?.preDunning?.status ?? null,
		rawResponse: response,
	};
};
