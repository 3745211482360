import memoize from 'lodash/memoize';
import type { ExpirableStorage, Storage, StorageNew, ExpirableStorageNew } from '../../types.tsx';
import {
	createStorageProvider,
	createExpirableStorageProvider,
	WebStorageType,
} from '../../utils.tsx';
import { makeSessionExpirableStore, makeSessionStore } from './utils.tsx';

export const createSessionStorageProvider = memoize((appPrefix: string): Storage | StorageNew =>
	createStorageProvider(WebStorageType.SessionStorage, appPrefix, makeSessionStore()),
);

export const createSessionExpirableStorageProvider = memoize(
	(appPrefix: string): ExpirableStorage | ExpirableStorageNew => {
		if (!appPrefix) {
			throw Error("appPrefix can not be empty. It's used to separate apps");
		}

		return createExpirableStorageProvider(
			WebStorageType.SessionStorage,
			appPrefix,
			makeSessionExpirableStore(),
		);
	},
);
