import React, { useState } from 'react';
import { styled } from '@compiled/react';
import Button from '@atlaskit/button';
import Heading from '@atlaskit/heading';
import MoreIcon from '@atlaskit/icon/core/migration/show-more-horizontal--more';
import { ButtonItem, MenuGroup, Section } from '@atlaskit/menu';
import Popup from '@atlaskit/popup';
import { xcss, Inline } from '@atlaskit/primitives';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { fg } from '@atlassian/jira-feature-gating';
import messages from './messages.tsx';

type RecommendationHeaderProps = {
	title: string;
	onDismissClicked: () => void;
	onLearnMoreClicked: () => void;
};

export const RecommendationHeader = ({
	title,
	onDismissClicked,
	onLearnMoreClicked,
}: RecommendationHeaderProps) => {
	const [isActionsMenuOpen, setIsActionsMenuOpen] = useState(false);
	const { formatMessage } = useIntl();

	return (
		<Inline xcss={HeadingItemStyles}>
			<Heading size="xxsmall" as="h2">
				<Inline xcss={TitleItemStyles}>{title}</Inline>
			</Heading>
			<Popup
				isOpen={isActionsMenuOpen}
				placement="bottom-start"
				onClose={() => setIsActionsMenuOpen(false)}
				content={() => (
					<MenuGroup onClick={(e) => e.stopPropagation()}>
						<Section>
							<ButtonItem onClick={onDismissClicked}>
								{formatMessage(messages.dismissButtonText)}
							</ButtonItem>
							<ButtonItem onClick={onLearnMoreClicked}>
								{formatMessage(messages.learnMoreButtonText)}
							</ButtonItem>
						</Section>
					</MenuGroup>
				)}
				shouldUseCaptureOnOutsideClick
				trigger={(triggerProps) => (
					<MeatballMenuTrigger
						{...triggerProps}
						appearance="subtle"
						onClick={() => setIsActionsMenuOpen(() => !isActionsMenuOpen)}
						iconBefore={
							<MoreIcon
								LEGACY_size="medium"
								spacing="spacious"
								label={
									fg('blu-6205_misc_nav3_a11y_fixes')
										? formatMessage(messages.moreButtonLabel, {
												title,
											})
										: 'more'
								}
							/>
						}
					/>
				)}
				shouldRenderToParent={fg('blu-6205_misc_nav3_a11y_fixes')}
			/>
		</Inline>
	);
};

const HeadingItemStyles = xcss({
	justifyContent: 'space-between',
	alignItems: 'center',
	paddingLeft: 'space.200',
	paddingRight: 'space.200',
	marginTop: 'space.050',
});

const TitleItemStyles = xcss({
	color: 'color.text.accent.gray',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const MeatballMenuTrigger = styled(Button)({
	width: '32px',
	height: '32px',
	'&:active': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		backgroundColor: `${token('color.background.selected', colors.N700)}`,
	},
});
