import React, { useMemo } from 'react';
import { styled } from '@compiled/react';
import AvatarGroup, { type AvatarGroupProps } from '@atlaskit/avatar-group';
import { N20, N500 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { noop } from '../../utils.tsx';

const MAX_OVERFLOW_USER_COUNT_DISPLAYED = 99;
const OVERFLOW_USER_MARKER = '$$$overflow-user-marker';

type AvatarGroupAlignment = 'horizontal' | 'vertical';
type TooltipPosition = 'top' | 'bottom';
type Props = Omit<AvatarGroupProps, 'onMoreClick'> & {
	alignment: AvatarGroupAlignment;
	maxAvatarCount: number;
	tooltipPosition: TooltipPosition;
};

/*
 * The original AkAvatarGroup cannot be used inside an AkFlag, because the More button (e.g. `+2`)
 * opens a popup whose z-index is too low, and gets displayed below the flag.
 */
export const FlagCompatibleAvatarGroup = ({
	alignment,
	maxAvatarCount,
	tooltipPosition,
	data,
	...props
}: Props) => {
	const { data: customData, overrides: customOverrides } = useMemo(
		() => getCustomAvatarGroupProps({ data, maxAvatarCount }),
		[data, maxAvatarCount],
	);

	const avatarGroup = (
		<AvatarGroup
			maxCount={maxAvatarCount}
			data={customData}
			overrides={customOverrides}
			boundariesElement="scrollParent"
			/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
			// @ts-ignore
			tooltipPosition={tooltipPosition}
			onMoreClick={noop} // Prevents the More popup. XXX: The popup has the wrong zIndex and displays under the flag
			{...props}
		/>
	);

	if (alignment === 'vertical') {
		return <VerticalAvatarGroupContainer>{avatarGroup}</VerticalAvatarGroupContainer>;
	}

	return avatarGroup;
};

export const getCustomAvatarGroupProps = ({
	data: users,
	maxAvatarCount,
}: Pick<AvatarGroupProps, 'data'> & { maxAvatarCount: number }) => {
	const overflows = users.length > maxAvatarCount;
	const lastFeaturedUser = maxAvatarCount - 1;
	const featuredUsers = users.slice(0, lastFeaturedUser);
	const overflowUsers = users.slice(lastFeaturedUser);
	const overflowMarkerDummyUser = getOverflowMarkerDummyUser(overflowUsers);
	const processedUsers = overflows ? [...featuredUsers, overflowMarkerDummyUser] : users;
	const overrides: AvatarGroupProps['overrides'] = {
		Avatar: {
			render: (AvatarComponent, avatarProps) => {
				if (
					avatarProps.name === overflowMarkerDummyUser.name &&
					avatarProps.key === overflowMarkerDummyUser.key
				) {
					return renderAvatarWithOverflowCount(AvatarComponent, avatarProps, overflowUsers.length);
				}
				return <AvatarComponent {...avatarProps} />;
			},
		},
	};
	return { overrides, data: processedUsers };
};

export const getOverflowMarkerDummyUser = (overflowUsers: AvatarGroupProps['data']) => ({
	name: overflowUsers.map((u) => u.name).join(', '),
	key: OVERFLOW_USER_MARKER,
});

type AvatarRender = NonNullable<
	NonNullable<NonNullable<AvatarGroupProps['overrides']>['Avatar']>['render']
>;
type AvatarRenderArgs = Parameters<AvatarRender>;

const renderAvatarWithOverflowCount = (
	AvatarComponent: AvatarRenderArgs[0],
	avatarProps: AvatarRenderArgs[1],
	overflowCount: number,
): ReturnType<AvatarRender> => (
	<AvatarComponent
		name={avatarProps.name}
		appearance={avatarProps.appearance}
		size={avatarProps.size}
		borderColor={avatarProps.borderColor}
		stackIndex={0}
	>
		{(avatarChildrenProps) => (
			<AvatarOverflowCountContainer {...avatarChildrenProps}>
				{`+${Math.min(overflowCount, MAX_OVERFLOW_USER_COUNT_DISPLAYED)}`}
			</AvatarOverflowCountContainer>
		)}
	</AvatarComponent>
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const AvatarOverflowCountContainer = styled.span({
	// !important is needed to ensure priority over Emotion styles we're receiving in className via avatarChildrenProps.
	// Those props come from AvatarGroup, and have some partial styles. The ordering between Emotion and Compiled
	// styles is not guaranteed, so we need to make sure our Compiled styles come on top.
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
	backgroundColor: `${token('color.background.neutral', N20)} !important`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
	color: `${token('color.text', N500)} !important`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
	textAlign: 'center !important',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
	fontWeight: `${token('font.weight.medium')} !important`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const VerticalAvatarGroupContainer = styled.span({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'> ul': {
		flexDirection: 'column',
		marginRight: 0,
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'> ul > li': {
		marginRight: 0,
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
		'&:not(:first-of-type)': {
			marginTop: token('space.negative.100'),
		},
	},
});
