import React, { useCallback } from 'react';
import {
	dismissFlag as createDismissFlag,
	type FlagComponentProps,
	ErrorFlag,
	useFlagService,
} from '@atlassian/jira-flags';
import { useIntl } from '@atlassian/jira-intl';
import { ProductDisplayNames, type CrossJoinTargetProduct } from '../../common/types.tsx';
import { generateId } from '../../utils/index.tsx';
import { messages } from './messages.tsx';

export const useRequestAccessErrorFlag = () => {
	const { showFlag, dismissFlag } = useFlagService();
	return useCallback(
		(product: CrossJoinTargetProduct) => {
			const id = `error-${generateId()}`;
			const dismiss = () => dismissFlag(createDismissFlag(id));

			showFlag({
				id,
				render: (props) => (
					<RequestAccessErrorFlag {...props} id={id} product={product} onDismiss={dismiss} />
				),
			});
		},
		[dismissFlag, showFlag],
	);
};

export type Props = {
	id: FlagComponentProps['id'];
	product: CrossJoinTargetProduct;
	onDismiss: FlagComponentProps['onDismissed'];
};

const JIRA_SUPPORT_LINK = 'https://support.atlassian.com/contact/';

export const RequestAccessErrorFlag = ({ onDismiss, ...props }: Props) => {
	const { formatMessage } = useIntl();

	return (
		<ErrorFlag
			{...props}
			onDismissed={onDismiss}
			title={formatMessage(messages.title, {
				productDisplayName: ProductDisplayNames[props.product],
			})}
			description={
				<>
					{/* eslint-disable-next-line @atlaskit/design-system/use-primitives-text */}
					<p>
						{formatMessage(messages.description)}
						<a href={JIRA_SUPPORT_LINK}>{formatMessage(messages.action)}</a>.
					</p>
				</>
			}
		/>
	);
};
