import memoizeOne from 'memoize-one';
import {
	getUserProperty,
	setUserProperties,
} from '@atlassian/jira-common-rest/src/api/latest/user-properties/index.tsx';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import type { AccountId } from '@atlassian/jira-shared-types/src/general.tsx';
import { functionWithCondition } from '@atlassian/jira-feature-flagging-utils';
import { fg } from '@atlassian/jira-feature-gating';
import { HELP_BUTTON_UI_STORAGE_KEY } from './constants.tsx';

export const updateHelpButtonState = async (
	accountId: AccountId | null,
	data: {
		helpPanelMenu: {
			releaseNotesNotifications: number;
		};
	},
) => {
	if (accountId !== null) {
		try {
			await setUserProperties(accountId, HELP_BUTTON_UI_STORAGE_KEY, JSON.stringify(data));
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (e: any) {
			fireErrorAnalytics({
				error: e,
				meta: {
					id: 'updateServerHelpButtonState',
					packageName: 'jiraAtlassianNavigation',
					teamName: 'navigation',
				},
			});
		}
	}
};

const getHelpButtonStateNew = memoizeOne(async (accountId: AccountId) => {
	try {
		// @ts-expect-error - Property 'helpPanelMenu' does not exist on type 'unknown'.
		const { helpPanelMenu: userPropertiesHelpButtonValue } = await getUserProperty(
			accountId,
			HELP_BUTTON_UI_STORAGE_KEY,
		);

		return userPropertiesHelpButtonValue;
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (e: any) {
		fireErrorAnalytics({
			error: e,
			meta: {
				id: 'getHelpButtonState',
				packageName: 'jiraAtlassianNavigation',
				teamName: 'navigation',
			},
		});
	}

	return null;
});

const getHelpButtonStateOld = async (accountId: AccountId) => {
	try {
		// @ts-expect-error - Property 'helpPanelMenu' does not exist on type 'unknown'.
		const { helpPanelMenu: userPropertiesHelpButtonValue } = await getUserProperty(
			accountId,
			HELP_BUTTON_UI_STORAGE_KEY,
		);

		return userPropertiesHelpButtonValue;
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (e: any) {
		fireErrorAnalytics({
			error: e,
			meta: {
				id: 'getHelpButtonState',
				packageName: 'jiraAtlassianNavigation',
				teamName: 'navigation',
			},
		});
	}

	return null;
};

export const getHelpButtonState = functionWithCondition(
	() => fg('user-properties-iv-llc-unnecessary-api'),
	getHelpButtonStateNew,
	getHelpButtonStateOld,
);
