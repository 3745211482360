import type { AnalyticsAttributes } from '@atlassian/jira-product-analytics-bridge';

/**
 * Convert undefined to string
 * to shown in error analytics monitoring
 */
export function handleUndefinedInObjectForAnalytics(
	input: AnalyticsAttributes,
): AnalyticsAttributes {
	if (typeof input === 'object') {
		return JSON.parse(
			JSON.stringify(input, (_, value) => (typeof value === 'undefined' ? 'undefined' : value)),
		);
	}
	return input;
}
