import React, { useCallback, useEffect, useRef, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { fg } from '@atlassian/jira-feature-gating';
import {
	fireOperationalAnalytics,
	fireUIAnalytics,
	useAnalyticsEvents,
} from '@atlassian/jira-product-analytics-bridge';
import { useAccountId } from '@atlassian/jira-tenant-context-controller/src/components/account-id/index.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { useLicensedProducts } from '@atlassian/jira-tenant-context-controller/src/components/licensed-products/index.tsx';
import { useIsActiveRoute, useRouterActions } from '@atlassian/react-resource-router';
import { requestAccess } from '../services/access-request/index.tsx';
import { fireErrorAnalytics } from '../services/analytics/index.tsx';
import {
	isFeatureVisited,
	getFeatureVisitedCount,
	snoozeFeatureFor30Days,
	increaseFeatureVisitedCount,
	useSnooze,
} from '../services/caching/index.tsx';
import { useFilteredUsersFromCollaborationGraph } from '../services/get-collaborators/index.tsx';
import { fireEligibilityChecked, fireFeatureExposed } from '../services/jira-jpd/analytics.tsx';
import { checkJpdCrossJoinNudgeEligibility } from '../services/jira-jpd/eligibility.tsx';
import { isExperimentEnabled } from '../services/jira-jpd/feature-flag.tsx';
import {
	useNudgeStateJiraToJpd,
	useNudgeStateV2a,
	useNudgeStateYourWork,
} from '../services/nudge-state/index.tsx';
import { fetchCachedAccessCheck, hasProductAccess } from '../services/permission-check/index.tsx';
import { useNudgeRecommendations } from '../services/recommendations/index.tsx';
import {
	fetchCachedIsConfluenceRACapable,
	fetchIsRequestAccessCapable,
	resetRACapableCache,
} from '../services/request-capabilities/index.tsx';
import { isSite7DaysOld } from '../services/site-eligibility/index.tsx';
import { useCollaborators } from '../services/use-collaborators/index.tsx';
import { useVisitedCount } from '../services/use-visited-count/index.tsx';
import { useRequestAccessErrorFlag } from './request-access-error-flag/index.tsx';
import { useRequestAccessPromptFlag } from './request-access-prompt-flag/index.tsx';
import { useRequestAccessSuccessFlag } from './request-access-success-flag/index.tsx';
import { ErrorBoundary } from './your-work-widget/ui/error-boundary/index.tsx';
import { CrossJoinNudgeYourWorkCard } from './your-work-widget/ui/index.tsx';

const MAX_NUMBER_OF_CONFLUENCE_COLLABORATORS = 5;
const MAX_NUMBER_OF_JPD_COLLABORATORS = 10;
const MAX_FEATURE_VISIT_COUNT = 3;

export const useCrossJoinNudge = () => {
	const sessionId = useRef(uuid());
	const getNudgeRecommendations = useNudgeRecommendations({ sessionId: sessionId.current });
	const cloudId = useCloudId();
	const getFilteredCollaborators = useFilteredUsersFromCollaborationGraph(
		cloudId,
		'confluence',
		MAX_NUMBER_OF_CONFLUENCE_COLLABORATORS,
	);
	const showRequestAccessPromptFlag = useRequestAccessPromptFlag();
	const showSuccessFlag = useRequestAccessSuccessFlag();
	const showErrorFlag = useRequestAccessErrorFlag();
	const { getNudgeState: getNudgeStateV2a, setNudgeDismissed: setNudgeDismissedV2a } =
		useNudgeStateV2a();
	const { push } = useRouterActions();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const userId = useAccountId() || '';
	const licensedProducts = useLicensedProducts();
	const isYourWorkRoute = useIsActiveRoute('your-work');

	return useCallback(async () => {
		try {
			if (isYourWorkRoute) {
				return;
			}

			if (!licensedProducts.confluence) {
				return;
			}

			if (isFeatureVisited('crossJoinNudgesConfluence', 30)) {
				return;
			}

			if (await hasProductAccess('confluence', cloudId)) {
				return;
			}

			if (!(await fetchIsRequestAccessCapable('confluence', cloudId))) {
				return;
			}

			if (!(await isSite7DaysOld('confluence', cloudId))) {
				return;
			}

			// Since V2A Rollout to 100%, all nudge will use V2A Logic
			if ((await getNudgeStateV2a()) === 'DISMISSED') {
				return;
			}

			const featureVisitedCount = await getFeatureVisitedCount(userId);
			if (featureVisitedCount >= MAX_FEATURE_VISIT_COUNT) {
				return;
			}

			const onSubmit = async () => {
				try {
					const accessStatus = await requestAccess(
						'confluence',
						cloudId,
						userId,
						'crossJoinNudgesConfluence',
						createAnalyticsEvent,
					);
					if (
						accessStatus === 'ACCESS_GRANTED' ||
						accessStatus === 'ACCESS_EXISTS' ||
						accessStatus === 'APPROVED_REQUEST_EXISTS'
					) {
						push('/wiki');
					} else if (
						accessStatus === 'PENDING_REQUEST_CREATED' ||
						accessStatus === 'PENDING_REQUEST_EXISTS'
					) {
						showSuccessFlag();
					} else {
						showErrorFlag('confluence');
					}
				} catch {
					showErrorFlag('confluence');
				}
			};

			const users = await getFilteredCollaborators();
			const isContextualBandit = users.length === 0;
			const entityId = isContextualBandit ? await getNudgeRecommendations() : undefined;

			showRequestAccessPromptFlag({
				users,
				onSubmit,
				onOptout: setNudgeDismissedV2a,
				snoozeFor30Days: () => snoozeFeatureFor30Days('crossJoinNudgesConfluence'),
				integration: 'crossJoinNudgesConfluence',
				isContextualBandit,
				entityId,
				featureVisitedCount,
			});
			increaseFeatureVisitedCount(userId, featureVisitedCount + 1);

			fireOperationalAnalytics(createAnalyticsEvent({}), 'slo.crossJoinNudgesConfluence succeeded');
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			fireErrorAnalytics('useCrossJoinNudges', error, { cloudId });
		}
	}, [
		cloudId,
		createAnalyticsEvent,
		getFilteredCollaborators,
		getNudgeRecommendations,
		getNudgeStateV2a,
		isYourWorkRoute,
		licensedProducts.confluence,
		push,
		setNudgeDismissedV2a,
		showErrorFlag,
		showRequestAccessPromptFlag,
		showSuccessFlag,
		userId,
	]);
};

export const useCrossJoinNudgeYourWorkEligibility = () => {
	const cloudId = useCloudId();
	const { isVisited } = useSnooze('crossJoinNudgesYourWork');
	const licensedProducts = useLicensedProducts();
	const { getNudgeState } = useNudgeStateYourWork();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const [isEligible, setIsEligible] = useState(false);

	useEffect(() => {
		const checkEligibility = async () => {
			try {
				if (!licensedProducts.confluence) {
					return;
				}

				if (isVisited()) {
					return;
				}

				if ((await getNudgeState()) === 'DISMISSED') {
					return;
				}

				fireOperationalAnalytics(createAnalyticsEvent({}), 'confluenceAccessCheck started', {
					touchpoint: 'jiraYourWork',
				});
				const hasAccessToConfluence = await fetchCachedAccessCheck(
					createAnalyticsEvent,
					'confluence',
					cloudId,
					'jiraYourWork',
				);

				if (hasAccessToConfluence === null) {
					return;
				}

				if (hasAccessToConfluence) {
					return;
				}

				fireOperationalAnalytics(createAnalyticsEvent({}), 'capabilitiesCheck started', {
					touchpoint: 'jiraYourWork',
				});
				const isRaCapable = await fetchCachedIsConfluenceRACapable(
					createAnalyticsEvent,
					cloudId,
					'jiraYourWork',
				);

				if (isRaCapable === null) {
					return;
				}

				if (!isRaCapable) {
					return;
				}

				fireOperationalAnalytics(createAnalyticsEvent({}), 'siteAgeCheck started', {
					touchpoint: 'jiraYourWork',
				});

				if (!(await isSite7DaysOld('confluence', cloudId))) {
					return;
				}

				fireOperationalAnalytics(
					createAnalyticsEvent({}),
					'promotionalCard eligibleCheckSucceeded',
					{
						touchpoint: 'jiraYourWork',
					},
				);
				setIsEligible(true);
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (error: any) {
				fireErrorAnalytics('useCrossJoinNudgeYourWorkEligibility', error, { cloudId });
			}
		};

		checkEligibility();
	}, [cloudId, getNudgeState, isVisited, licensedProducts.confluence, createAnalyticsEvent]);

	return isEligible;
};

const CrossJoinNudgeYourWorkWithChecks = () => {
	const cloudId = useCloudId();
	const userId = useAccountId() || '';
	const showSuccessFlag = useRequestAccessSuccessFlag();
	const showErrorFlag = useRequestAccessErrorFlag();
	const { setNudgeDismissed } = useNudgeStateYourWork();
	const { snoozeFor7Days, snoozeFor30Days, increaseVisitedCount } =
		useSnooze('crossJoinNudgesYourWork');
	const { push } = useRouterActions();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const [isDismissed, setIsDismissed] = useState(false);

	const { collaborators, isLoading: isCollaboratorsLoading } = useCollaborators(true);
	const { visitedCount, isLoading: isVisitedCountLoading } = useVisitedCount(
		true,
		'crossJoinNudgesYourWork',
	);

	const onDismiss = useCallback(() => {
		snoozeFor7Days();
		increaseVisitedCount(visitedCount + 1);
		setIsDismissed(true);
		fireUIAnalytics(createAnalyticsEvent({}), 'promotionalCard dismissed', 'crossJoinNudges');
	}, [createAnalyticsEvent, visitedCount, increaseVisitedCount, snoozeFor7Days]);

	const onSubmit = useCallback(async () => {
		fireUIAnalytics(createAnalyticsEvent({}), 'promotionalCard submitted', 'crossJoinNudges');
		try {
			const accessStatus = await requestAccess(
				'confluence',
				cloudId,
				userId,
				'crossJoinNudgesYourWork',
				createAnalyticsEvent,
			);
			resetRACapableCache();
			if (
				accessStatus === 'ACCESS_GRANTED' ||
				accessStatus === 'ACCESS_EXISTS' ||
				accessStatus === 'APPROVED_REQUEST_EXISTS'
			) {
				push('/wiki');
			} else if (
				accessStatus === 'PENDING_REQUEST_CREATED' ||
				accessStatus === 'PENDING_REQUEST_EXISTS'
			) {
				showSuccessFlag();
				setIsDismissed(true);
			} else {
				fireOperationalAnalytics(createAnalyticsEvent({}), 'promotionalCard submitError', {
					accessStatus,
				});
				showErrorFlag('confluence');
			}
		} catch (err) {
			fireOperationalAnalytics(createAnalyticsEvent({}), 'promotionalCard submitError', { err });
			showErrorFlag('confluence');
		}
	}, [cloudId, createAnalyticsEvent, push, showErrorFlag, showSuccessFlag, userId]);

	const onSnooze = useCallback(() => {
		snoozeFor30Days();
		increaseVisitedCount(visitedCount + 1);
		setIsDismissed(true);
		fireUIAnalytics(createAnalyticsEvent({}), 'promotionalCard snoozed', 'crossJoinNudges');
	}, [createAnalyticsEvent, visitedCount, increaseVisitedCount, snoozeFor30Days]);

	const onOptOut = useCallback(() => {
		setNudgeDismissed();
		setIsDismissed(true);
		fireUIAnalytics(createAnalyticsEvent({}), 'promotionalCard optedOut', 'crossJoinNudges');
	}, [createAnalyticsEvent, setNudgeDismissed]);

	let secondaryAction;
	switch (visitedCount) {
		case 0:
			secondaryAction = 'none';
			break;
		case 1:
			secondaryAction = 'snooze';
			break;
		default:
			secondaryAction = 'opt-out';
	}

	const onRender = useCallback(() => {
		fireUIAnalytics(createAnalyticsEvent({}), 'promotionalCard shown', 'crossJoinNudges', {
			secondaryAction,
			collaborators: collaborators.length,
		});
	}, [collaborators.length, createAnalyticsEvent, secondaryAction]);

	const shouldShow = !isCollaboratorsLoading && !isVisitedCountLoading && !isDismissed;

	return shouldShow ? (
		<CrossJoinNudgeYourWorkCard
			collaborators={collaborators}
			onRender={onRender}
			onDismiss={secondaryAction === 'none' ? onDismiss : onSnooze}
			onSubmit={onSubmit}
			onSnooze={secondaryAction === 'snooze' ? onSnooze : undefined}
			onOptOut={secondaryAction === 'opt-out' ? onOptOut : undefined}
		/>
	) : null;
};

export const CrossJoinNudgeYourWork = () => (
	<ErrorBoundary>
		<CrossJoinNudgeYourWorkWithChecks />
	</ErrorBoundary>
);

export const useJpdCrossJoinNudgeEligibility = () => {
	const cloudId = useCloudId();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const licensedProducts = useLicensedProducts();
	const isJpdRoute = useIsActiveRoute('polaris-ideas');

	return useCallback(async () => {
		try {
			if (!fg('cross_join_nudges_jira_to_jpd_gate')) {
				return false;
			}

			if (isFeatureVisited('crossJoinNudgesJiraToJpd', 30)) {
				return false;
			}

			if (isJpdRoute) {
				return false;
			}

			const eligibilityResponse = await checkJpdCrossJoinNudgeEligibility({
				cloudId,
				licensedProducts,
			});
			if (!eligibilityResponse.eligible) {
				fireEligibilityChecked(createAnalyticsEvent, eligibilityResponse.ineligibilityReason);
				return false;
			}
			fireEligibilityChecked(createAnalyticsEvent, 'ELIGIBLE');
			return true;

			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			fireErrorAnalytics('crossJoinNudgesJiraToJpd', error, { cloudId });
			return false;
		}
	}, [cloudId, createAnalyticsEvent, isJpdRoute, licensedProducts]);
};

export const useJpdCrossJoinNudge = () => {
	const cloudId = useCloudId();
	const userId = useAccountId() || '';
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const getFilteredCollaborators = useFilteredUsersFromCollaborationGraph(
		cloudId,
		'jira-product-discovery',
		MAX_NUMBER_OF_JPD_COLLABORATORS,
	);
	const showRequestAccessPromptFlag = useRequestAccessPromptFlag();
	const showSuccessFlag = useRequestAccessSuccessFlag();
	const showErrorFlag = useRequestAccessErrorFlag();
	const { push } = useRouterActions();
	const { snoozeFor30Days, getVisitedCount, increaseVisitedCount } = useSnooze(
		'crossJoinNudgesJiraToJpd',
	);
	const { getNudgeState, setNudgeDismissed } = useNudgeStateJiraToJpd();

	return useCallback(async () => {
		try {
			if (!fg('cross_join_nudges_jira_to_jpd_gate')) {
				return;
			}
			const collaboratorsOnJpd = await getFilteredCollaborators();
			const onSubmit = async () => {
				try {
					const accessStatus = await requestAccess(
						'jira-product-discovery',
						cloudId,
						userId,
						'crossJoinNudgesJiraToJpd',
						createAnalyticsEvent,
					);
					if (
						accessStatus === 'ACCESS_GRANTED' ||
						accessStatus === 'ACCESS_EXISTS' ||
						accessStatus === 'APPROVED_REQUEST_EXISTS'
					) {
						push('/jira/projects');
					} else if (
						accessStatus === 'PENDING_REQUEST_CREATED' ||
						accessStatus === 'PENDING_REQUEST_EXISTS'
					) {
						showSuccessFlag();
					} else {
						showErrorFlag('jira-product-discovery');
					}
				} catch {
					showErrorFlag('jira-product-discovery');
				}
			};

			fireFeatureExposed(createAnalyticsEvent);

			const isUserOptOut = (await getNudgeState()) === 'DISMISSED';
			const featureVisitedCount = await getVisitedCount();
			if (!isExperimentEnabled() || isUserOptOut || featureVisitedCount >= MAX_FEATURE_VISIT_COUNT)
				return;

			showRequestAccessPromptFlag({
				users: collaboratorsOnJpd,
				onSubmit,
				onOptout: setNudgeDismissed,
				snoozeFor30Days,
				integration: 'crossJoinNudgesJiraToJpd',
				featureVisitedCount,
			});

			increaseVisitedCount(featureVisitedCount + 1);
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			fireErrorAnalytics('crossJoinNudgesJiraToJpd', error, { cloudId });
		}
	}, [
		cloudId,
		createAnalyticsEvent,
		getFilteredCollaborators,
		getNudgeState,
		getVisitedCount,
		increaseVisitedCount,
		push,
		setNudgeDismissed,
		showErrorFlag,
		showRequestAccessPromptFlag,
		showSuccessFlag,
		snoozeFor30Days,
		userId,
	]);
};
