import {
	CREATE_PROJECT_TASK,
	CREATE_ISSUE_TASK,
	INVITE_TEAMMATES_TASK,
	INVITE_TEAMMATES_VIDEO_TASK,
	CAPTURE_INTEGRATIONS_TASK,
	FIND_HELP_TASK,
	SET_UP_BOARD_TASK,
	GET_MOBILE_APP_TASK,
	MAP_OUT_YOUR_PROJECT_GOALS_TASK,
	IDENTIFY_SMALL_CHUNKS_OF_WORK_TASK,
	MONITOR_MANAGE_RISK_TASK,
	PREMIUM_REVERSE_TRIAL_TASK,
	PREMIUM_EDITION_OVERVIEW_TASK,
	PREMIUM_EDITION_OVERVIEW_TASK_NON_ADMIN,
	STANDARD_EDITION_OVERVIEW_TASK,
	ADVANCED_ROADMAPS_TASK,
	CHANGE_CONTROL_TASK,
	AUTOMATIONS_TASK,
	SITE_ADMINISTRATIONS_TASK,
	USER_MANAGEMENT_TASK,
	SCRUM_ONBOARDING_TASK,
	CUSTOMIZE_SITE_NAME_TASK,
	GET_TO_KNOW_YOUR_TEMPLATE_TASK,
	CREATE_BUSINESS_PROJECT_FROM_JSW_QUICKSTART_TASK,
	TRY_ATLASSIAN_UNIVERSITY_ONBOARDING_TASK,
	GET_STARTED_LIST_TASK,
	CREATE_ISSUE_TASK_SIMPLIFIED,
	ADD_COLUMNS_TASK,
	CALENDAR_VIEW_TASK,
} from '../../../../common/constants.tsx';
import type { Cards, TaskList } from '../../../../common/types.tsx';
import { messages } from './messages.tsx';

export const Experiment = {
	scrumOnboarding: 'experiment.scrumOnboarding',
	customizeSiteName: 'experiment.customizeSiteName',
	jswReverseTrial: 'experiment.jswReverseTrial',
	rtQuickstartM1: 'experiment.rtQuickstartM1',
	wizard: 'experiment.wizard',
} as const;

export const Experience = {
	kanbanOnboarding: 'experience.kanbanOnboarding',
	fedRamp: 'experience.fedRamp',
} as const;

export const ProjectStyle = {
	teamManaged: 'projectStyle.teamManaged',
	companyManaged: 'projectStyle.companyManaged',
} as const;

export const ProjectProperties = {
	isCreatedFromCustomerTemplate: 'projectProperties.isCreatedFromCustomerTemplate',
} as const;

export const UserRole = {
	siteAdmin: 'userRole.siteAdmin',
	jiraAdmin: 'userRole.jiraAdmin',
	admin: 'userRole.admin',
	projectAdmin: 'userRole.projectAdmin',
} as const;

export const Edition = {
	free: 'productEdition.free',
	standard: 'productEdition.standard',
	premium: 'productEdition.premium',
} as const;

export const Route = {
	softwareRoadmap: 'route.softwareRoadmap',
	softwareBacklog: 'route.softwareBacklog',
	softwareBoard: 'route.softwareBoard',
	softwareList: 'route.softwareList',
	projectSettingsSoftwareAccess: 'route.projectSettingsSoftwareAccess',
	issue: 'route.issue',
	softwareCalendar: 'route.softwareCalendar',
} as const;

export const Permissions = {
	notCanCreateProject: 'permissions.notCanCreateProject',
	hasPlanEditPermissions: 'permissions.hasPlanEditPermissions',
} as const;

export const Trait = {
	crossProjectBoardToPlanUpsell: 'trait.crossProjectBoardToPlanUpsell',
} as const;

const DEFAULT_TASK_LIST = [
	ADD_COLUMNS_TASK,
	CREATE_PROJECT_TASK,
	CREATE_ISSUE_TASK,
	CREATE_ISSUE_TASK_SIMPLIFIED,
	TRY_ATLASSIAN_UNIVERSITY_ONBOARDING_TASK,
	INVITE_TEAMMATES_TASK,
	INVITE_TEAMMATES_VIDEO_TASK,
	CAPTURE_INTEGRATIONS_TASK,
	FIND_HELP_TASK,
	SET_UP_BOARD_TASK,
	GET_MOBILE_APP_TASK,
	PREMIUM_REVERSE_TRIAL_TASK,
	PREMIUM_EDITION_OVERVIEW_TASK,
	PREMIUM_EDITION_OVERVIEW_TASK_NON_ADMIN,
	STANDARD_EDITION_OVERVIEW_TASK,
	MAP_OUT_YOUR_PROJECT_GOALS_TASK,
	IDENTIFY_SMALL_CHUNKS_OF_WORK_TASK,
	MONITOR_MANAGE_RISK_TASK,
	SCRUM_ONBOARDING_TASK,
	CUSTOMIZE_SITE_NAME_TASK,
	GET_TO_KNOW_YOUR_TEMPLATE_TASK,
	CREATE_BUSINESS_PROJECT_FROM_JSW_QUICKSTART_TASK,
	GET_STARTED_LIST_TASK,
	CALENDAR_VIEW_TASK,
];

export const TASK_LIST: TaskList = Object.freeze({
	root: {
		title: messages.rootTitle,
		items: DEFAULT_TASK_LIST,
	},
	reverseTrialRoot: {
		title: messages.reverseTrialRootTitle,
		items: DEFAULT_TASK_LIST,
	},
	[PREMIUM_REVERSE_TRIAL_TASK]: {
		title: messages.reverseTrialCardTitle,
		items: [
			ADVANCED_ROADMAPS_TASK,
			CHANGE_CONTROL_TASK,
			AUTOMATIONS_TASK,
			SITE_ADMINISTRATIONS_TASK,
			USER_MANAGEMENT_TASK,
		],
	},
	[PREMIUM_EDITION_OVERVIEW_TASK]: {
		title: messages.premiumPlanTitle,
		items: [
			ADVANCED_ROADMAPS_TASK,
			CHANGE_CONTROL_TASK,
			AUTOMATIONS_TASK,
			SITE_ADMINISTRATIONS_TASK,
			USER_MANAGEMENT_TASK,
		],
	},
	[PREMIUM_EDITION_OVERVIEW_TASK_NON_ADMIN]: {
		title: messages.premiumPlanTitle,
		items: [ADVANCED_ROADMAPS_TASK, AUTOMATIONS_TASK],
	},
	[STANDARD_EDITION_OVERVIEW_TASK]: {
		title: messages.standardPlanTitle,
		items: [AUTOMATIONS_TASK, USER_MANAGEMENT_TASK, SITE_ADMINISTRATIONS_TASK],
	},
});

const PLAN_ONBOARDING_TASKS: Cards = {
	[PREMIUM_REVERSE_TRIAL_TASK]: {
		position: {
			default: 900,
		},
		visibility: {
			default: false,
			conditional: [
				{
					condition: [Experiment.rtQuickstartM1, Edition.premium],
					value: false,
				},
				{
					condition: [Experiment.jswReverseTrial, Edition.premium],
					value: true,
				},
			],
		},
	},
	[PREMIUM_EDITION_OVERVIEW_TASK]: {
		position: {
			default: 900,
		},
		visibility: {
			default: false,
			conditional: [
				{
					condition: [Experiment.jswReverseTrial, Edition.premium, UserRole.admin],
					value: false,
				},
				{
					condition: [Edition.premium, UserRole.admin],
					value: true,
				},
			],
		},
	},
	[PREMIUM_EDITION_OVERVIEW_TASK_NON_ADMIN]: {
		position: {
			default: 900,
		},
		visibility: {
			default: false,
			conditional: [
				{
					// This condition needs to be the opposite condition to PREMIUM_EDITION_OVERVIEW_TASK
					condition: [Edition.premium, UserRole.admin],
					value: false,
				},
				{
					condition: [Experiment.jswReverseTrial, Edition.premium],
					value: false,
				},
				{
					condition: [Edition.premium],
					value: true,
				},
				{
					condition: [Trait.crossProjectBoardToPlanUpsell, Permissions.hasPlanEditPermissions],
					value: true,
				},
			],
		},
	},
	[STANDARD_EDITION_OVERVIEW_TASK]: {
		position: {
			default: 900,
		},
		visibility: {
			default: false,
			conditional: [
				{
					condition: [Edition.standard, UserRole.admin],
					value: true,
				},
			],
		},
	},
	[ADVANCED_ROADMAPS_TASK]: {
		position: { default: 105 },
		visibility: {
			default: false,
			conditional: [
				{
					condition: [Trait.crossProjectBoardToPlanUpsell, Permissions.hasPlanEditPermissions],
					value: true,
				},
				{
					condition: [Edition.premium],
					value: true,
				},
			],
		},
	},
	[AUTOMATIONS_TASK]: {
		position: { default: 110 },
		visibility: {
			default: false,
			conditional: [
				{
					condition: [Trait.crossProjectBoardToPlanUpsell, Permissions.hasPlanEditPermissions],
					value: true,
				},
				{
					condition: [Edition.premium, UserRole.admin],
					value: true,
				},
				{
					condition: [Edition.standard, UserRole.admin],
					value: true,
				},
			],
		},
	},
	[USER_MANAGEMENT_TASK]: {
		position: { default: 115 },
		visibility: {
			default: false,
			conditional: [
				{
					condition: [Edition.premium, UserRole.admin],
					value: true,
				},
				{
					condition: [Edition.standard, UserRole.admin],
					value: true,
				},
			],
		},
	},
	[CHANGE_CONTROL_TASK]: {
		position: { default: 120 },
		visibility: {
			default: false,
			conditional: [
				{
					condition: [Edition.premium, UserRole.siteAdmin],
					value: true,
				},
			],
		},
	},
	[SITE_ADMINISTRATIONS_TASK]: {
		position: { default: 125 },
		visibility: {
			default: false,
			conditional: [
				{
					condition: [Edition.premium, UserRole.siteAdmin],
					value: true,
				},
				{
					condition: [Edition.standard, UserRole.siteAdmin],
					value: true,
				},
			],
		},
	},
};

const CUSTOMER_TEMPLATE_ONBOARDING_TASKS: Cards = {
	[GET_TO_KNOW_YOUR_TEMPLATE_TASK]: {
		position: { default: 0 },
		visibility: {
			default: false,
			conditional: [
				{
					condition: [ProjectProperties.isCreatedFromCustomerTemplate, UserRole.siteAdmin],
					value: true,
				},
			],
		},
	},
};

// https://hello.atlassian.net/wiki/spaces/agile/pages/1093679513/Documentation+QS+Card+manager
export const TASK_VARIANTS: Cards = {
	...PLAN_ONBOARDING_TASKS,
	...CUSTOMER_TEMPLATE_ONBOARDING_TASKS,
	[CREATE_PROJECT_TASK]: {
		position: { default: 0 },
		visibility: {
			default: true,
			conditional: [
				{
					condition: [Permissions.notCanCreateProject],
					value: false,
				},
				{
					condition: [ProjectProperties.isCreatedFromCustomerTemplate],
					value: false,
				},
			],
		},
	},
	[CUSTOMIZE_SITE_NAME_TASK]: {
		position: { default: 50 },
		visibility: {
			default: false,
			conditional: [
				{
					condition: [UserRole.siteAdmin, Experiment.customizeSiteName],
					value: true,
				},
			],
		},
	},
	[CALENDAR_VIEW_TASK]: {
		position: { default: 100 },
		visibility: {
			default: false,
			conditional: [
				{
					value: true,
					condition: [Route.softwareCalendar],
				},
			],
		},
	},
	[SCRUM_ONBOARDING_TASK]: {
		position: { default: 150 },
		visibility: {
			default: false,
			conditional: [
				{
					condition: [UserRole.siteAdmin, Experiment.scrumOnboarding, Route.softwareBacklog],
					value: true,
				},
			],
		},
	},
	[MAP_OUT_YOUR_PROJECT_GOALS_TASK]: {
		position: { default: 150 },
		visibility: {
			default: false,
			conditional: [
				{
					condition: [Route.softwareRoadmap],
					value: true,
				},
			],
		},
	},
	[IDENTIFY_SMALL_CHUNKS_OF_WORK_TASK]: {
		position: { default: 151 },
		visibility: {
			default: false,
			conditional: [
				{
					condition: [Route.softwareRoadmap],
					value: true,
				},
			],
		},
	},
	[MONITOR_MANAGE_RISK_TASK]: {
		position: { default: 152 },
		visibility: {
			default: false,
			conditional: [
				{
					condition: [Route.softwareRoadmap],
					value: true,
				},
			],
		},
	},
	[SET_UP_BOARD_TASK]: {
		position: {
			default: 200,
		},
		visibility: {
			default: false,
			conditional: [
				{
					condition: [Experience.kanbanOnboarding, Route.softwareBoard],
					value: false,
				},
				{
					condition: [Experience.kanbanOnboarding],
					value: false,
				},
				{
					condition: [Route.softwareList],
					value: false,
				},
				{
					condition: [UserRole.projectAdmin, ProjectStyle.teamManaged],
					value: true,
				},
			],
		},
	},
	[CREATE_ISSUE_TASK]: {
		position: { default: 300 },
		visibility: {
			default: true,
			conditional: [
				{
					condition: [Experience.kanbanOnboarding, Route.softwareBoard],
					value: false,
				},
				{
					condition: [Experiment.wizard, UserRole.admin, Route.softwareList],
					value: false,
				},
			],
		},
	},
	[CREATE_ISSUE_TASK_SIMPLIFIED]: {
		position: { default: 300 },
		visibility: {
			default: false,
			conditional: [
				{
					condition: [Experience.kanbanOnboarding, Route.softwareBoard],
					value: true,
				},
			],
		},
	},
	[ADD_COLUMNS_TASK]: {
		position: {
			default: 310,
		},
		visibility: {
			default: false,
			conditional: [
				{
					condition: [Experience.kanbanOnboarding, Route.softwareBoard],
					value: true,
				},
			],
		},
	},
	[GET_STARTED_LIST_TASK]: {
		position: { default: 320 },
		visibility: {
			default: false,
			conditional: [
				{
					condition: [Route.softwareList],
					value: true,
				},
			],
		},
	},
	[TRY_ATLASSIAN_UNIVERSITY_ONBOARDING_TASK]: {
		position: { default: 350 },
		visibility: {
			default: true,
			conditional: [
				{
					condition: [Experience.kanbanOnboarding, Route.softwareBoard],
					value: false,
				},
				{
					condition: [Experiment.wizard, UserRole.admin, Route.softwareList],
					value: false,
				},
			],
		},
	},
	[INVITE_TEAMMATES_TASK]: {
		position: { default: 400 },
		visibility: {
			default: true,
			conditional: [
				{
					condition: [UserRole.projectAdmin, ProjectStyle.teamManaged],
					value: false,
				},
				{
					condition: [UserRole.admin, ProjectStyle.teamManaged],
					value: false,
				},
			],
		},
	},
	[INVITE_TEAMMATES_VIDEO_TASK]: {
		position: {
			default: 400,
			conditional: [
				{
					condition: [Experience.kanbanOnboarding, Route.softwareBoard],
					value: 600,
				},
			],
		},
		visibility: {
			default: false,
			conditional: [
				{
					condition: [UserRole.projectAdmin, ProjectStyle.teamManaged],
					value: true,
				},
				{
					condition: [UserRole.admin, ProjectStyle.teamManaged],
					value: true,
				},
			],
		},
	},
	[CAPTURE_INTEGRATIONS_TASK]: {
		position: {
			default: 500,
		},
		visibility: {
			default: true,
			conditional: [
				{
					condition: [Experience.fedRamp],
					value: false,
				},
			],
		},
	},
	[GET_MOBILE_APP_TASK]: {
		position: { default: 600 },
		visibility: {
			default: true,
			conditional: [
				{
					condition: [Experience.fedRamp],
					value: false,
				},
				{
					condition: [Experience.kanbanOnboarding, Route.softwareBoard],
					value: false,
				},
				{
					condition: [Experiment.wizard, UserRole.admin, Route.softwareList],
					value: false,
				},
			],
		},
	},
	[FIND_HELP_TASK]: {
		position: { default: 700 },
		visibility: {
			default: true,
			conditional: [
				{
					condition: [Experience.kanbanOnboarding, Route.softwareBoard],
					value: false,
				},
				{
					condition: [Experiment.wizard, UserRole.admin, Route.softwareList],
					value: false,
				},
			],
		},
	},
	[CREATE_BUSINESS_PROJECT_FROM_JSW_QUICKSTART_TASK]: {
		position: { default: 800 },
		visibility: {
			default: true,
			conditional: [
				{ condition: [Permissions.notCanCreateProject], value: false },
				{
					condition: [Experience.kanbanOnboarding, Route.softwareBoard],
					value: false,
				},
				{
					condition: [Experiment.wizard, UserRole.admin, Route.softwareList],
					value: false,
				},
			],
		},
	},
};
