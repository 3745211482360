import React, { useCallback } from 'react';
import {
	dismissFlag as createDismissFlag,
	type FlagComponentProps,
	SuccessFlag,
	useFlagService,
} from '@atlassian/jira-flags';
import { useIntl } from '@atlassian/jira-intl';
import { generateId } from '../../utils/index.tsx';
import { messages } from './messages.tsx';

export const useRequestAccessSuccessFlag = () => {
	const { showFlag, dismissFlag } = useFlagService();
	return useCallback(() => {
		const id = `success-${generateId()}`;
		const dismiss = () => dismissFlag(createDismissFlag(id));

		showFlag({
			id,
			render: (props) => <RequestAccessSuccessFlag {...props} id={id} onDismiss={dismiss} />,
		});
	}, [dismissFlag, showFlag]);
};

export type Props = {
	id: FlagComponentProps['id'];
	onDismiss: FlagComponentProps['onDismissed'];
};

export const RequestAccessSuccessFlag = ({ onDismiss, ...props }: Props) => {
	const { formatMessage } = useIntl();

	return (
		<SuccessFlag
			{...props}
			onDismissed={onDismiss}
			title={formatMessage(messages.title)}
			// eslint-disable-next-line @atlaskit/design-system/use-primitives-text
			description={<p>{formatMessage(messages.description)}</p>}
		/>
	);
};
