import React, { useMemo } from 'react';
import DashboardIcon from '@atlaskit/icon/core/migration/dashboard';
import { HeadingItem } from '@atlaskit/menu';
import { DASHBOARDS_ITEM_TYPE } from '@atlassian/jira-favourite-change-provider/src/model/constants.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { testIdConcat } from '@atlassian/jira-navigation-apps-common/src/utils/test-id.tsx';
import { dashboardViewRoute } from '@atlassian/jira-router-routes-dashboard-routes/src/dashboardViewRoute.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { DEFAULT_LOAD_COUNT_FAV } from '../../../../common/constants.tsx';
import { FavoriteButton } from '../../../../common/ui/menu/favourite-button/main.tsx';
import { MenuItem } from '../../../../common/ui/menu/item/index.tsx';
import { MenuItemViewAll } from '../../../../common/ui/menu/view-all/main.tsx';
import { useDashboardNavItems as useItems } from '../../../../controllers/items/index.tsx';
import messages from './messages.tsx';
import type { FavoriteContentProps } from './types.tsx';

export const FavoriteContent = ({ testIdPrefix }: FavoriteContentProps) => {
	const { formatMessage } = useIntl();
	const { favoriteItems, changeFavorite: onChangeFavorite, changedItems } = useItems();

	const itemsToShow = useMemo(
		() => favoriteItems.slice(0, DEFAULT_LOAD_COUNT_FAV),
		[favoriteItems],
	);

	const testId = testIdConcat(testIdPrefix, 'items-starred');

	const dashboardsUrl = '/jira/dashboards?page=1&sortKey=favourite&sortOrder=DESC';

	return favoriteItems.length > 0 ? (
		<span data-testid={testId}>
			<HeadingItem testId={testIdConcat(testId, 'heading')}>
				{formatMessage(messages.starred)}
			</HeadingItem>
			{itemsToShow.map((item, idx) => {
				const { url, id, favourite: isFavourite, metadata, title } = item;
				const contextItem = changedItems[id.toString()];
				const isPending = contextItem && contextItem.pending === true ? contextItem.pending : false;

				return (
					<MenuItem
						analytics={{
							actionSubjectId: 'dashboardsNavigationMenuItem',
							menuItemId: id,
							menuItemType: 'starred',
							position: idx,
							starred: Boolean(isFavourite),
						}}
						description={metadata}
						href={url}
						iconBefore={<DashboardIcon spacing="spacious" label="dashboard" />}
						iconAfter={
							<FavoriteButton
								id={String(id)}
								isFavorite={Boolean(isFavourite)}
								favoriteItemName={title}
								isPending={isPending}
								menuItemType="starred"
								onChangeFavorite={onChangeFavorite}
								type={DASHBOARDS_ITEM_TYPE}
							/>
						}
						key={id}
						testId={testIdConcat(testId, `item-${idx}`)}
						to={dashboardViewRoute}
						params={{ dashboardId: String(id) }}
						aria-label={
							fg('blu-6205_misc_nav3_a11y_fixes') ? `${title} ${metadata || ''}` : undefined
						}
					>
						{title}
					</MenuItem>
				);
			})}
			{favoriteItems.length >= DEFAULT_LOAD_COUNT_FAV && (
				<MenuItemViewAll
					analytics={{
						actionSubjectId: 'dashboardsNavigationMenuItem',
						menuItemId: 'viewAll',
						// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
						position: DEFAULT_LOAD_COUNT_FAV as number,
					}}
					href={dashboardsUrl}
					testId={testIdConcat(testId, 'item-view-all')}
					to={dashboardsUrl}
				/>
			)}
		</span>
	) : null;
};
