import type { AnalyticsEventPayload, UIAnalyticsEvent } from '@atlaskit/analytics-next';
import fireJiraErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import type { AnalyticsAttributes } from '@atlassian/jira-product-analytics-bridge';

export type CreateAnalyticsEvent = (payload: AnalyticsEventPayload) => UIAnalyticsEvent;

export const fireErrorAnalytics = (
	id: string,
	error: Error,
	attributes: AnalyticsAttributes = {},
) =>
	fireJiraErrorAnalytics({
		meta: {
			id,
			packageName: 'jiraCrossJoinNudges',
		},
		attributes,
		error,
		sendToPrivacyUnsafeSplunk: true,
	});
