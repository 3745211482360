import memoize from 'lodash/memoize';
import type { ExpirableStorage, Storage, StorageNew, ExpirableStorageNew } from '../../types.tsx';
import {
	createStorageProvider,
	createExpirableStorageProvider,
	WebStorageType,
} from '../../utils.tsx';
import { makeLocalExpirableStore, makeLocalStore } from './utils.tsx';

export const createLocalStorageProvider = memoize((appPrefix: string): Storage | StorageNew =>
	createStorageProvider(WebStorageType.LocalStorage, appPrefix, makeLocalStore()),
);

export const createLocalExpirableStorageProvider = memoize(
	(appPrefix: string): ExpirableStorage | ExpirableStorageNew => {
		return createExpirableStorageProvider(
			WebStorageType.LocalStorage,
			appPrefix,
			makeLocalExpirableStore(),
		);
	},
);
