import React, { useCallback } from 'react';
import type { PrimitiveType } from 'intl-messageformat';
import { dismissFlag as createDismissFlag, useFlagService } from '@atlassian/jira-flags';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { useAccountId } from '@atlassian/jira-tenant-context-controller/src/components/account-id/index.tsx';
import { useIntl, type MessageDescriptor } from '@atlassian/jira-intl';
import type { CrossJoinIntegraton } from '../../common/types.tsx';
import { getDynamicMessages, type EntityId } from '../../services/dynamic-messaging/index.tsx';
import type { Collaborators } from '../../services/get-collaborators/types.tsx';
import { generateId } from '../../utils/index.tsx';
import { messages } from '../../common/ui/request-access-prompt-flag/messages.tsx';

import {
	RequestAccessPromptFlag as RequestAccessPromptFlagCommon,
	type CJNMessageStrings,
} from './flag-common.tsx';

export const useRequestAccessPromptFlag = () => {
	const { showFlag, dismissFlag } = useFlagService();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { formatMessage } = useIntl();
	const userId = useAccountId() || '';

	return useCallback(
		({
			users,
			onOptout,
			onSubmit,
			snoozeFor30Days,
			integration,
			isContextualBandit = false,
			entityId = 'default',
			sessionId = '',
			featureVisitedCount = 0,
		}: {
			users: Collaborators;
			onOptout: () => unknown;
			onSubmit: () => Promise<void>;
			snoozeFor30Days: () => unknown;
			integration: CrossJoinIntegraton;
			isContextualBandit?: boolean;
			entityId?: EntityId;
			sessionId?: string;
			featureVisitedCount?: number;
		}) => {
			const id = `request-access-${generateId()}`;

			const dismissTheFlag = () => dismissFlag(createDismissFlag(id));

			const eventAttributes = {
				collaborators: users.length,
				userId,
				isContextualBandit,
				entityId,
				sessionId,
				featureVisitedCount,
			};

			const submit = async () => {
				fireUIAnalytics(createAnalyticsEvent({}), 'button clicked', integration, eventAttributes);
				dismissTheFlag();
				await onSubmit();
			};

			const dismiss = () => {
				fireUIAnalytics(createAnalyticsEvent({}), 'flag dismissed', integration, eventAttributes);
				dismissTheFlag();
			};

			const dismissAndOptout = () => {
				dismiss();
				fireUIAnalytics(createAnalyticsEvent({}), 'flag optedOut', integration, eventAttributes);
				onOptout();
			};

			const dismissAndSnooze = () => {
				dismissTheFlag();
				fireUIAnalytics(createAnalyticsEvent({}), 'flag snoozed', integration, eventAttributes);
				snoozeFor30Days();
			};

			fireUIAnalytics(createAnalyticsEvent({}), 'nudge viewed', integration, eventAttributes);

			const cjnMessages = getCJNMessageStrings(
				formatMessage,
				integration,
				isContextualBandit,
				entityId,
				featureVisitedCount,
			);
			const avatarOrientation =
				integration === 'crossJoinNudgesJiraToJpd' ? 'horizontal' : 'vertical';

			showFlag({
				id,
				render: (props) => (
					<RequestAccessPromptFlagCommon
						{...props}
						id={id}
						users={users}
						onDismiss={dismiss}
						onSnoozeAndDismiss={dismissAndSnooze}
						onOptoutAndDismiss={dismissAndOptout}
						onSubmit={submit}
						avatarOrientation={avatarOrientation}
						messages={cjnMessages}
						featureVisitedCount={featureVisitedCount}
					/>
				),
			});
		},
		[formatMessage, createAnalyticsEvent, dismissFlag, showFlag, userId],
	);
};
export const getCJNMessageStrings = (
	formatMessage: (md: MessageDescriptor, values?: Record<string, PrimitiveType>) => string,
	integration: CrossJoinIntegraton,
	isContextualBandit: boolean,
	entityId: EntityId,
	featureVisitedCount: number,
): CJNMessageStrings => {
	if (integration === 'crossJoinNudgesJiraToJpd') {
		return {
			title: formatMessage(messages.titleJpdNudge),
			description: formatMessage(messages.descriptionJpdNudge),
			joinButton: formatMessage(messages.getStartedButton),
			remindMeLaterButton: formatMessage(messages.remindMeLaterButton),
			notInterestedButton: formatMessage(messages.doNotShowAgainButton),
		};
	}
	if (integration === 'crossJoinNudgesConfluence') {
		const dynamicMessages = getDynamicMessages(isContextualBandit, entityId) || messages;
		const infoFlagTitle = isContextualBandit
			? formatMessage(messages.title)
			: formatMessage(messages.urgencyTitle, { viewCount: featureVisitedCount });

		return {
			title: infoFlagTitle,
			description: formatMessage(dynamicMessages.description),
			joinButton: formatMessage(dynamicMessages.joinButton),
			remindMeLaterButton: formatMessage(messages.remindMeLaterButton),
			notInterestedButton: formatMessage(messages.notInterestedButton),
		};
	}
	throw new Error(`Unexpected integration ${integration}`);
};
