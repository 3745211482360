import React, {
	type ComponentType,
	type ReactNode,
	type RefObject,
	useCallback,
	useContext,
	useMemo,
} from 'react';
import { type PreloadedEntryPoint, loadEntryPoint } from 'react-relay';
import { SkeletonItem } from '@atlaskit/menu';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import Modal, {
	ModalBody,
	ModalHeader,
	ModalFooter,
	ModalTitle,
	type ModalDialogProps,
} from '@atlaskit/modal-dialog';
import { Stack } from '@atlaskit/primitives';
import usePressTracing from '@atlaskit/react-ufo/use-press-tracing';
import { fg } from '@atlassian/jira-feature-gating';
import ShortcutScope from '@atlassian/jira-common-components-keyboard-shortcuts/src/shortcut-scope.tsx';
import type {
	AnyEntryPoint,
	ComponentOfEntryPoint,
	ParamsOfEntryPoint,
	RuntimePropsOfEntryPoint,
} from '@atlassian/jira-entry-point/src/common/types.tsx';
import {
	InternalEntryPointContainer,
	type InternalEntryPointContainerProps,
} from '@atlassian/jira-internal-entry-point-container/src/index.tsx';
import useMergeRefs from '@atlassian/jira-merge-refs/src/index.tsx';
import { ModalContext } from '@atlassian/jira-modal-context/src/ModalContext.tsx';
import { useJiraRelayEnvironmentProvider } from '@atlassian/jira-relay-environment-provider/src/index.tsx';
import { usePressablePreloadRef } from './usePressablePreloadRef.tsx';

export type ModalEntryPoint = AnyEntryPoint;

export type ModalEntryPointPressableTriggerProps<TEntryPoint extends ModalEntryPoint> = {
	children: ({ ref }: { ref: RefObject<HTMLElement> }) => ReactNode;
	entryPoint: TEntryPoint;
	entryPointParams?: keyof ParamsOfEntryPoint<TEntryPoint> extends never
		? undefined
		: ParamsOfEntryPoint<TEntryPoint>;
	entryPointProps?: Omit<
		RuntimePropsOfEntryPoint<TEntryPoint>,
		'onClose' | 'setShouldCloseOnOverlayClick' | 'setShouldCloseOnEscapePress'
	> & {
		onClose?: () => void;
	};
	Fallback?: ComponentType<{ onClose?: () => void }>;
	interactionName?: string;
	title?: ReactNode;
	modalProps?: Omit<ModalDialogProps, 'onClose'>;
	useInternalModal: boolean;
} & (keyof ParamsOfEntryPoint<TEntryPoint> extends never
	? {}
	: {
			entryPointParams: unknown;
		}) &
	Partial<Pick<InternalEntryPointContainerProps<TEntryPoint>, 'errorAttributes'>> & {
		forwardedRef?: React.Ref<HTMLElement>;
	};

const emptyObject = {};

export const ModalEntryPointPressableTrigger = <TEntryPoint extends ModalEntryPoint>({
	children,
	entryPoint,
	entryPointParams,
	entryPointProps,
	errorAttributes,
	Fallback,
	interactionName,
	title,
	modalProps,
	useInternalModal,
	forwardedRef,
}: ModalEntryPointPressableTriggerProps<TEntryPoint>) => {
	const { openModal } = useContext(ModalContext);
	const environmentProvider = useJiraRelayEnvironmentProvider();

	const metricName = useMemo((): string => {
		if (interactionName) {
			return interactionName;
		}

		// @ts-expect-error Need to fix the types on this
		return entryPoint?.root?.getModuleName() ?? 'unknown';
	}, [entryPoint, interactionName]);

	// eslint-disable-next-line jira/ufo/valid-labels
	const pressTracing = usePressTracing(metricName);

	const load = useCallback(
		() =>
			loadEntryPoint(
				environmentProvider,
				entryPoint,
				// @ts-expect-error TS cannot infer that emptyObject is the correct params
				// type for TEntryPoint even when performing an explicit undefined check
				entryPointParams ?? emptyObject,
			),
		[entryPoint, entryPointParams, environmentProvider],
	);

	const onBeforeLoad = useCallback(() => {
		if (interactionName) {
			pressTracing();
		}
	}, [interactionName, pressTracing]);

	const onLoad = useCallback(
		({
			reference: entryPointReference,
			dispose,
		}: {
			reference: PreloadedEntryPoint<ComponentOfEntryPoint<TEntryPoint>>;
			dispose: () => void;
		}) => {
			openModal(
				({
					onClose: closeModal,
					shouldCloseOnOverlayClick,
					shouldCloseOnEscapePress,
					setShouldCloseOnOverlayClick,
					setShouldCloseOnEscapePress,
				}) => {
					const onClose = () => {
						entryPointProps?.onClose?.();
						closeModal();
						dispose();
					};
					const defaultFallbackInner = (
						<>
							<ModalHeader>
								{title ? (
									<ModalTitle>{title}</ModalTitle>
								) : (
									<Stack grow="fill">
										<SkeletonItem />
									</Stack>
								)}
							</ModalHeader>
							<ModalBody>
								<SkeletonItem />
								<SkeletonItem />
								<SkeletonItem />
							</ModalBody>
							<ModalFooter />
						</>
					);

					const defaultFallback = useInternalModal ? (
						defaultFallbackInner
					) : (
						<Modal onClose={closeModal} width={modalProps?.width} height={modalProps?.height}>
							{defaultFallbackInner}
						</Modal>
					);
					const container = (
						<InternalEntryPointContainer<typeof entryPoint>
							entryPointReference={entryPointReference}
							errorAttributes={errorAttributes}
							errorFallback="flag"
							fallback={Fallback ? <Fallback onClose={closeModal} /> : defaultFallback}
							id={metricName}
							placeholderName="modal-entry-point-pressable-trigger-container"
							// @ts-expect-error Some type mismatch, should fix it one day
							runtimeProps={{
								...(entryPointProps ?? emptyObject),
								onClose,
								...(fg('jpo-31059_modal_close_behaviour_controls')
									? {
											setShouldCloseOnOverlayClick,
											setShouldCloseOnEscapePress,
											shouldCloseOnOverlayClick,
											shouldCloseOnEscapePress,
										}
									: {}),
							}}
						/>
					);
					return useInternalModal ? (
						<ShortcutScope>
							<Modal
								{...modalProps}
								onClose={onClose}
								{...(fg('jpo-31059_modal_close_behaviour_controls')
									? {
											shouldCloseOnOverlayClick:
												shouldCloseOnOverlayClick ?? modalProps?.shouldCloseOnOverlayClick,
											shouldCloseOnEscapePress:
												shouldCloseOnEscapePress ?? modalProps?.shouldCloseOnEscapePress,
										}
									: {})}
							>
								{container}
							</Modal>
						</ShortcutScope>
					) : (
						container
					);
				},
			);
		},
		[
			openModal,
			title,
			useInternalModal,
			modalProps,
			errorAttributes,
			Fallback,
			metricName,
			entryPointProps,
		],
	);

	const triggerRef = usePressablePreloadRef({
		load,
		onBeforeLoad,
		onLoad,
	});

	if (forwardedRef != null) {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		const ref = useMergeRefs(triggerRef, forwardedRef);
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		return children({ ref: ref as unknown as RefObject<HTMLElement> });
	}
	return children({ ref: triggerRef });
};
