import React, { useEffect } from 'react';
import { styled } from '@compiled/react';
import ShortcutIcon from '@atlaskit/icon/core/migration/link-external--shortcut';
import Lozenge from '@atlaskit/lozenge';
import { HeadingItem } from '@atlaskit/menu';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import type { ProjectType } from '@atlassian/jira-common-constants/src/index.tsx';
import { getProductEdition } from '@atlassian/jira-common-editions/src/get-product-edition/index.tsx';
import getExplicitlyLicensedProducts from '@atlassian/jira-common-get-explicitly-licensed-products/src/index.tsx';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import { useIntlV2 as useIntl } from '@atlassian/jira-intl/src/v2/use-intl.tsx';
import { useProject } from '@atlassian/jira-navigation-apps-sidebar-common/src/controllers/project/index.tsx';
import {
	fireUIAnalytics,
	FireUiAnalytics,
	useAnalyticsEvents,
} from '@atlassian/jira-product-analytics-bridge';
import type { ApplicationKey } from '@atlassian/jira-shared-types/src/application-key.tsx';
import { FREE_EDITION, STANDARD_EDITION } from '@atlassian/jira-shared-types/src/edition.tsx';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import { getTrialLength } from '@atlassian/jira-up-flow-common/src/common/utils.tsx';
import { useUpflowModal } from '@atlassian/jira-upflow-iframe-plugin/src/controllers/index.tsx';
import { MenuItem } from '../../../../common/ui/menu/item/index.tsx';
import { MenuList, MenuListItem } from '../../../../common/ui/menu/list/index.tsx';
import {
	IFRAME_FLOW,
	GROWTH_FEATURE,
	CLICKED_EVENT_NAME,
	VIEWED_EVENT_NAME,
	PACKAGE_NAME,
	ANALYTIC_SOURCE,
} from './constants.tsx';
import type { UpgradeEditionMenuItemProps, MenuItemInfo } from './types.tsx';
import {
	getFreeEditionMenuItem,
	shouldRenderMenuItem,
	getHref,
	getActivePageApplicationKey,
} from './utils.tsx';

export const RenderUpgradeMenuItem = ({ product }: { product: ApplicationKey | null }) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const tenantContext = useTenantContext();
	const productEdition = product ? getProductEdition(product, tenantContext) : FREE_EDITION;
	const trialLength = getTrialLength(productEdition, STANDARD_EDITION);
	const freeEditionMenuItemInfo: MenuItemInfo = getFreeEditionMenuItem(useIntl, trialLength);
	const [, { mountUpflowModal }] = useUpflowModal();

	return (
		<>
			<FireUiAnalytics
				eventName={VIEWED_EVENT_NAME}
				attributes={{
					currentEdition: productEdition,
					growthFeature: GROWTH_FEATURE,
					touchpointId: freeEditionMenuItemInfo.touchpointId,
					flowId: freeEditionMenuItemInfo.flowId,
					computedProduct: product,
				}}
			/>
			<HeadingItem>{freeEditionMenuItemInfo.heading}</HeadingItem>
			<MenuList>
				<MenuListItem>
					<MenuItem
						analytics={{
							actionSubjectId: 'persistentUpgradeNavigationMenuItem',
							menuItemId: freeEditionMenuItemInfo.touchpointId,
							menuItemType: 'action',
							position: 0,
						}}
						onClick={() => {
							const analyticEvent = createAnalyticsEvent({});

							fireUIAnalytics(analyticEvent, CLICKED_EVENT_NAME, {
								currentEdition: productEdition,
								growthFeature: GROWTH_FEATURE,
								touchpointId: freeEditionMenuItemInfo.touchpointId,
								flowId: freeEditionMenuItemInfo.flowId,
								computedProduct: product,
							});

							if (product && freeEditionMenuItemInfo.displayUpflowModal === true)
								mountUpflowModal({
									targetEdition: STANDARD_EDITION,
									product,
									flow: IFRAME_FLOW,
									touchpointId: freeEditionMenuItemInfo.touchpointId,
								});
						}}
						href={getHref(freeEditionMenuItemInfo, product)}
						target={product ? undefined : '_blank'}
						iconAfter={
							product ? undefined : (
								<ShortcutIcon
									LEGACY_size="small"
									color={token('color.icon.subtle', colors.N100)}
									label=""
								/>
							)
						}
					>
						{freeEditionMenuItemInfo.content}
						<LozengeContainer>
							<Lozenge appearance={freeEditionMenuItemInfo.lozengeAppearance} isBold>
								{freeEditionMenuItemInfo.tagContent}{' '}
							</Lozenge>
						</LozengeContainer>
					</MenuItem>
				</MenuListItem>
			</MenuList>
		</>
	);
};

const UpgradeEditionMenuItemOld = ({ setUpgradeEditionMenuShown }: UpgradeEditionMenuItemProps) => {
	const tenantContext = useTenantContext();
	const { data: projectData, loading: projectDataLoading } = useProject();

	if (projectDataLoading) {
		return null;
	}

	const { isSiteAdmin } = tenantContext;
	const explicitlyLicensedProducts = getExplicitlyLicensedProducts(tenantContext);
	const applicationKey = getActivePageApplicationKey(
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		projectData?.type as ProjectType | undefined,
		explicitlyLicensedProducts,
	);

	if (
		!shouldRenderMenuItem(isSiteAdmin, applicationKey, explicitlyLicensedProducts, tenantContext)
	) {
		setUpgradeEditionMenuShown(false);
		return null;
	}

	// Add separator bar in the menu
	setUpgradeEditionMenuShown(true);

	return (
		<JSErrorBoundary id={ANALYTIC_SOURCE} packageName={PACKAGE_NAME} fallback="unmount">
			<RenderUpgradeMenuItem product={applicationKey} />
		</JSErrorBoundary>
	);
};

const UpgradeEditionMenuItemNew = ({ setUpgradeEditionMenuShown }: UpgradeEditionMenuItemProps) => {
	const tenantContext = useTenantContext();
	const { data: projectData, loading: projectDataLoading } = useProject();

	const { isSiteAdmin } = tenantContext;
	const explicitlyLicensedProducts = getExplicitlyLicensedProducts(tenantContext);
	const applicationKey = getActivePageApplicationKey(
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		projectData?.type as ProjectType | undefined,
		explicitlyLicensedProducts,
	);

	const shouldRender = shouldRenderMenuItem(
		isSiteAdmin,
		applicationKey,
		explicitlyLicensedProducts,
		tenantContext,
	);

	useEffect(() => {
		if (projectDataLoading) {
			return;
		}
		// Add separator bar in the menu if the menu item should be rendered
		setUpgradeEditionMenuShown(shouldRender);
	}, [projectDataLoading, setUpgradeEditionMenuShown, shouldRender]);

	if (projectDataLoading) {
		return null;
	}

	if (!shouldRender) {
		return null;
	}

	return (
		<JSErrorBoundary id={ANALYTIC_SOURCE} packageName={PACKAGE_NAME} fallback="unmount">
			<RenderUpgradeMenuItem product={applicationKey} />
		</JSErrorBoundary>
	);
};

export const UpgradeEditionMenuItem = componentWithFG(
	'jira-concurrent-update-while-rendering',
	UpgradeEditionMenuItemNew,
	UpgradeEditionMenuItemOld,
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const LozengeContainer = styled.span({
	marginLeft: token('space.100'),
});
