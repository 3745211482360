/* index.tsx generated by @compiled/babel-plugin v0.36.0 */
import { forwardRef } from 'react';
import { ax, ix } from "@compiled/react/runtime";
import React, { useCallback } from 'react';
import { ROUTE_NAMES_DASHBOARD_EDIT } from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import CreateDashboardModal from '@atlassian/jira-create-dashboard-modal/src/async.tsx';
import { useRouteByName } from '@atlassian/jira-platform-router-utils/src/index.tsx';
import { useSwitchModals } from '@atlassian/jira-switch-modals-controller/src/index.tsx';
import { useIsAnonymous } from '@atlassian/jira-tenant-context-controller/src/components/is-anonymous/index.tsx';
import { useRouterActions, generatePath } from '@atlassian/react-resource-router';
import { MODAL_ID } from '../../../common/constants.tsx';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const Absolute = forwardRef(({
  as: C = "div",
  style: __cmpls,
  ...__cmplp
}, __cmplr) => {
  return <C {...__cmplp} style={__cmpls} ref={__cmplr} className={ax(["_kqswstnw", __cmplp.className])} />;
});
if (process.env.NODE_ENV !== 'production') {
  Absolute.displayName = 'Absolute';
}
export const Dashboards = () => {
  const isAnonymous = useIsAnonymous();
  const [isOpen, {
    off: modalClose
  }] = useSwitchModals(MODAL_ID.DASHBOARD_CREATE);
  const {
    path
  } = useRouteByName(ROUTE_NAMES_DASHBOARD_EDIT) ?? {};
  const {
    push
  } = useRouterActions();
  const onSuccess = useCallback(
  // @ts-expect-error - TS7006 - Parameter '_' implicitly has an 'any' type. | TS7031 - Binding element 'id' implicitly has an 'any' type.
  (_, {
    id
  }) => {
    path && push(generatePath(path, {
      dashboardId: id
    }));
    modalClose();
  }, [modalClose, path, push]);
  if (isAnonymous) {
    return null;
  }
  return isOpen ? <Absolute>
			<CreateDashboardModal onSuccess={onSuccess} onCancel={modalClose} />
		</Absolute> : null;
};