import get from 'lodash/get';
import {
	PERSONAL_SETTINGS_PAGE_MODULE,
	SOURCE_NAVIGATION,
} from '@atlassian/jira-forge-ui-constants/src/constants.tsx';
import type { PersonalSettingsPage } from '@atlassian/jira-forge-ui-types/src/common/types/extension.tsx';
import { parseExtensionId } from '@atlassian/jira-forge-ui-utils-internal/src/utils/parse-extension-id/index.tsx';
import { fetchModules } from '@atlassian/jira-forge-ui-utils/src/utils/fetch-modules/index.tsx';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav4/index.tsx';
import { getAppsPersonalSettingsPath } from '@atlassian/jira-personal-settings-paths/src/index.tsx';
import {
	createStore,
	createSubscriber,
	createHook,
	createContainer,
	type SubscriberComponent,
	type HookReturnValue,
	type ContainerComponent,
	type StoreActionApi,
	type SetState,
	type GetState,
	type BoundActions,
} from '@atlassian/react-sweet-state';
import type { StoreConfig, PublicState, State } from './types.tsx';

const getItemHref = (extension: PersonalSettingsPage) => {
	const { appId, envId } = parseExtensionId(extension.id);
	return `${getAppsPersonalSettingsPath()}/${appId}/${envId}`;
};

const handleFetch =
	({ cloudId, isAnonymous }: { cloudId: string; isAnonymous: boolean }) =>
	async ({ setState, getState }: { setState: SetState<State>; getState: GetState<State> }) => {
		const { fetchForgeModule, isFetching } = getState();

		if (!isFetching) {
			try {
				// If Nav4 is enabled, we don't need to fetch personalSettings items since they are implemented elsewhere.
				if (getWillShowNav4()) return;

				const promise = fetchForgeModule({
					cloudId,
					isAnonymous,
					context: {},
					includeHidden: false,
					types: [PERSONAL_SETTINGS_PAGE_MODULE],
					source: SOURCE_NAVIGATION,
				});
				setState({
					isFetching: true,
					fetchError: null,
					promise,
				});

				const data = await promise;
				const personalSettingsPageData = get(data, PERSONAL_SETTINGS_PAGE_MODULE) || [];

				const items = personalSettingsPageData.map((extension: PersonalSettingsPage) => ({
					id: extension.id,
					title: extension.properties.title,
					avatarUrl: extension.properties.icon,
					url: getItemHref(extension),
					environmentType: extension.environmentType,
					environmentKey: extension.environmentKey,
					module: PERSONAL_SETTINGS_PAGE_MODULE,
				}));

				setState({
					items,
					isFetching: false,
					fetchError: null,
					promise: null,
				});
			} catch (fetchError) {
				setState({
					...getState(),
					fetchError: fetchError instanceof Error ? fetchError : null,
					promise: null,
				});
			}
		}
	};

const actions = {
	load:
		({ cloudId, isAnonymous }: { cloudId: string; isAnonymous: boolean }) =>
		async ({ dispatch }: StoreActionApi<State>) => {
			await dispatch(handleFetch({ cloudId, isAnonymous }));
		},
} as const;

type Actions = typeof actions;

type ReturnType = {
	ForgePersonalSettingsItemsSubscriber: SubscriberComponent<PublicState, Actions, undefined>;
	useForgePersonalSettingsItems: () => HookReturnValue<PublicState, BoundActions<State, Actions>>;
	ForgePersonalSettingsItemsTestContainer: ContainerComponent<State>;
};
export const createForgePersonalSettingsItemsStore = (defaults: StoreConfig): ReturnType => {
	const initialState = {
		fetchForgeModule: defaults.fetchForgeModule,
		items: [],
		promise: null,
		fetchError: null,
		isFetching: false,
	};

	const Store = createStore<State, Actions>({
		name: 'forge-personal-settings-items-store',
		actions,
		initialState,
	});

	const ForgePersonalSettingsItemsTestContainer = createContainer<State, Actions, State>(Store, {
		onInit: () => (api: StoreActionApi<State>, props: State) => {
			api.setState(props);
		},
	});

	const selector = ({ items, fetchError, promise, isFetching }: State) => ({
		items,
		fetchError,
		promise,
		isFetching,
	});

	const ForgePersonalSettingsItemsSubscriber = createSubscriber<
		State,
		Actions,
		PublicState,
		undefined
	>(Store, {
		displayName: 'ForgePersonalSettingsItemsSubscriber',
		selector,
	});
	const useForgePersonalSettingsItems = createHook(Store, { selector });
	return {
		ForgePersonalSettingsItemsSubscriber,
		useForgePersonalSettingsItems,
		ForgePersonalSettingsItemsTestContainer,
	};
};

export const { useForgePersonalSettingsItems } = createForgePersonalSettingsItemsStore({
	fetchForgeModule: fetchModules,
});
