/**
 * @generated SignedSource<<8a3f51c153d3b7fe998180764934a6f2>>
 * @relayHash 73e0bb7569fcd9f92e71b0f85aff9096
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID a26d4e935e9be681e324f39c22c1a9aa997f113864a513e7dc2e3475f12570e7

import type { ConcreteRequest, Query } from 'relay-runtime';
export type CcpBillingInterval = "DAY" | "MONTH" | "WEEK" | "YEAR" | "%future added value";
export type CcpEntitlementPreDunningStatus = "IN_PRE_DUNNING" | "NOT_IN_PRE_DUNNING" | "%future added value";
export type commerceSharedApiEditionAwarenessTrialQuery$variables = {
  cloudId: string;
  isInGracePeriodFixFgEnabled: boolean;
  isTrialCountdownFgExpEnabled: boolean;
  productKey: string;
};
export type commerceSharedApiEditionAwarenessTrialQuery$data = {
  readonly tenantContexts: ReadonlyArray<{
    readonly entitlementInfo: {
      readonly entitlement: {
        readonly offering?: {
          readonly trial?: {
            readonly lengthDays: number | null | undefined;
          } | null | undefined;
        } | null | undefined;
        readonly overriddenEdition?: string | null | undefined;
        readonly preDunning?: {
          readonly status: CcpEntitlementPreDunningStatus | null | undefined;
        } | null | undefined;
        readonly subscription: {
          readonly accountDetails: {
            readonly invoiceGroup: {
              readonly id?: string;
              readonly invoiceable: boolean;
            } | null | undefined;
          } | null | undefined;
          readonly pricingPlan: {
            readonly primaryCycle: {
              readonly interval: CcpBillingInterval;
            } | null | undefined;
            readonly type: string;
          } | null | undefined;
        } | null | undefined;
        readonly transactionAccount: {
          readonly isCurrentUserBillingAdmin: boolean;
          readonly isManagedByPartner: boolean;
          readonly key: string;
        } | null | undefined;
      } | null | undefined;
    } | null | undefined;
  } | null | undefined> | null | undefined;
};
export type commerceSharedApiEditionAwarenessTrialQuery = {
  response: commerceSharedApiEditionAwarenessTrialQuery$data;
  variables: commerceSharedApiEditionAwarenessTrialQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cloudId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isInGracePeriodFixFgEnabled"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isTrialCountdownFgExpEnabled"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "productKey"
},
v4 = [
  {
    "items": [
      {
        "kind": "Variable",
        "name": "cloudIds.0",
        "variableName": "cloudId"
      }
    ],
    "kind": "ListValue",
    "name": "cloudIds"
  }
],
v5 = [
  {
    "kind": "Variable",
    "name": "hamsProductKey",
    "variableName": "productKey"
  }
],
v6 = [
  {
    "kind": "Literal",
    "name": "where",
    "value": {
      "AND": [
        {
          "inTrialOrPreDunning": true
        }
      ]
    }
  }
],
v7 = {
  "kind": "ScalarField",
  "name": "invoiceable"
},
v8 = {
  "kind": "ScalarField",
  "name": "id"
},
v9 = [
  (v8/*: any*/)
],
v10 = {
  "kind": "InlineFragment",
  "selections": (v9/*: any*/),
  "type": "CcpInvoiceGroup"
},
v11 = {
  "kind": "ScalarField",
  "name": "interval"
},
v12 = {
  "kind": "ScalarField",
  "name": "type"
},
v13 = {
  "kind": "ScalarField",
  "name": "key"
},
v14 = {
  "kind": "ScalarField",
  "name": "isCurrentUserBillingAdmin"
},
v15 = {
  "kind": "ScalarField",
  "name": "isManagedByPartner"
},
v16 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "overriddenEdition"
    }
  ],
  "type": "HamsEntitlement"
},
v17 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "concreteType": "CcpOfferingTrial",
      "kind": "LinkedField",
      "name": "trial",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "lengthDays"
        }
      ]
    }
  ],
  "type": "CcpOffering"
},
v18 = {
  "kind": "ScalarField",
  "name": "status"
},
v19 = {
  "kind": "ScalarField",
  "name": "__typename"
},
v20 = {
  "kind": "InlineFragment",
  "selections": (v9/*: any*/),
  "type": "Node",
  "abstractKey": "__isNode"
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "name": "commerceSharedApiEditionAwarenessTrialQuery",
    "selections": [
      {
        "args": (v4/*: any*/),
        "concreteType": "TenantContext",
        "kind": "LinkedField",
        "name": "tenantContexts",
        "plural": true,
        "selections": [
          {
            "args": (v5/*: any*/),
            "kind": "LinkedField",
            "name": "entitlementInfo",
            "plural": false,
            "selections": [
              {
                "args": (v6/*: any*/),
                "kind": "LinkedField",
                "name": "entitlement",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "name": "subscription",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "name": "accountDetails",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "name": "invoiceGroup",
                            "plural": false,
                            "selections": [
                              {
                                "kind": "RequiredField",
                                "field": (v7/*: any*/),
                                "action": "THROW",
                                "path": "tenantContexts.entitlementInfo.entitlement.subscription.accountDetails.invoiceGroup.invoiceable"
                              },
                              (v10/*: any*/)
                            ]
                          }
                        ]
                      },
                      {
                        "kind": "LinkedField",
                        "name": "pricingPlan",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "name": "primaryCycle",
                            "plural": false,
                            "selections": [
                              {
                                "kind": "RequiredField",
                                "field": (v11/*: any*/),
                                "action": "THROW",
                                "path": "tenantContexts.entitlementInfo.entitlement.subscription.pricingPlan.primaryCycle.interval"
                              }
                            ]
                          },
                          {
                            "kind": "RequiredField",
                            "field": (v12/*: any*/),
                            "action": "THROW",
                            "path": "tenantContexts.entitlementInfo.entitlement.subscription.pricingPlan.type"
                          }
                        ]
                      }
                    ]
                  },
                  {
                    "kind": "LinkedField",
                    "name": "transactionAccount",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "RequiredField",
                        "field": (v13/*: any*/),
                        "action": "THROW",
                        "path": "tenantContexts.entitlementInfo.entitlement.transactionAccount.key"
                      },
                      {
                        "kind": "RequiredField",
                        "field": (v14/*: any*/),
                        "action": "THROW",
                        "path": "tenantContexts.entitlementInfo.entitlement.transactionAccount.isCurrentUserBillingAdmin"
                      },
                      {
                        "kind": "RequiredField",
                        "field": (v15/*: any*/),
                        "action": "THROW",
                        "path": "tenantContexts.entitlementInfo.entitlement.transactionAccount.isManagedByPartner"
                      }
                    ]
                  },
                  (v16/*: any*/),
                  {
                    "condition": "isTrialCountdownFgExpEnabled",
                    "kind": "Condition",
                    "passingValue": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "name": "offering",
                        "plural": false,
                        "selections": [
                          (v17/*: any*/)
                        ]
                      }
                    ]
                  },
                  {
                    "condition": "isInGracePeriodFixFgEnabled",
                    "kind": "Condition",
                    "passingValue": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "name": "preDunning",
                        "plural": false,
                        "selections": [
                          (v18/*: any*/)
                        ]
                      }
                    ]
                  }
                ],
                "storageKey": "entitlement(where:{\"AND\":[{\"inTrialOrPreDunning\":true}]})"
              }
            ]
          }
        ]
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v3/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "commerceSharedApiEditionAwarenessTrialQuery",
    "selections": [
      {
        "args": (v4/*: any*/),
        "concreteType": "TenantContext",
        "kind": "LinkedField",
        "name": "tenantContexts",
        "plural": true,
        "selections": [
          {
            "args": (v5/*: any*/),
            "kind": "LinkedField",
            "name": "entitlementInfo",
            "plural": false,
            "selections": [
              (v19/*: any*/),
              {
                "args": (v6/*: any*/),
                "kind": "LinkedField",
                "name": "entitlement",
                "plural": false,
                "selections": [
                  (v19/*: any*/),
                  {
                    "kind": "LinkedField",
                    "name": "subscription",
                    "plural": false,
                    "selections": [
                      (v19/*: any*/),
                      {
                        "kind": "LinkedField",
                        "name": "accountDetails",
                        "plural": false,
                        "selections": [
                          (v19/*: any*/),
                          {
                            "kind": "LinkedField",
                            "name": "invoiceGroup",
                            "plural": false,
                            "selections": [
                              (v19/*: any*/),
                              (v7/*: any*/),
                              (v10/*: any*/)
                            ]
                          }
                        ]
                      },
                      {
                        "kind": "LinkedField",
                        "name": "pricingPlan",
                        "plural": false,
                        "selections": [
                          (v19/*: any*/),
                          {
                            "kind": "LinkedField",
                            "name": "primaryCycle",
                            "plural": false,
                            "selections": [
                              (v19/*: any*/),
                              (v11/*: any*/)
                            ]
                          },
                          (v12/*: any*/),
                          (v20/*: any*/)
                        ]
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": (v9/*: any*/),
                        "type": "CcpSubscription"
                      }
                    ]
                  },
                  {
                    "kind": "LinkedField",
                    "name": "transactionAccount",
                    "plural": false,
                    "selections": [
                      (v19/*: any*/),
                      (v13/*: any*/),
                      (v14/*: any*/),
                      (v15/*: any*/),
                      {
                        "kind": "InlineFragment",
                        "selections": (v9/*: any*/),
                        "type": "CcpTransactionAccount"
                      }
                    ]
                  },
                  (v16/*: any*/),
                  {
                    "condition": "isTrialCountdownFgExpEnabled",
                    "kind": "Condition",
                    "passingValue": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "name": "offering",
                        "plural": false,
                        "selections": [
                          (v19/*: any*/),
                          (v17/*: any*/),
                          (v20/*: any*/)
                        ]
                      }
                    ]
                  },
                  {
                    "condition": "isInGracePeriodFixFgEnabled",
                    "kind": "Condition",
                    "passingValue": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "name": "preDunning",
                        "plural": false,
                        "selections": [
                          (v19/*: any*/),
                          (v18/*: any*/)
                        ]
                      }
                    ]
                  },
                  (v8/*: any*/)
                ],
                "storageKey": "entitlement(where:{\"AND\":[{\"inTrialOrPreDunning\":true}]})"
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "a26d4e935e9be681e324f39c22c1a9aa997f113864a513e7dc2e3475f12570e7",
    "metadata": {},
    "name": "commerceSharedApiEditionAwarenessTrialQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "7edf1870581993241b5e20b9206e30e3";

export default node;
