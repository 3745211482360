import React, { useCallback, useEffect, useMemo, type Ref } from 'react';
import { graphql, useLazyLoadQuery } from 'react-relay';
import { EditionAwarenessButton as Button } from '@atlassian/growth-pattern-library-edition-awareness-button';
import {
	convertToJiraProjectType,
	CORE_PROJECT,
} from '@atlassian/jira-common-constants/src/project-types.tsx';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { getProductOfferingKeyOrThrow } from '@atlassian/jira-growth-utils/src/services/get-product-offering-key/index.tsx';
import { useIntl, type MessageDescriptor } from '@atlassian/jira-intl';
import { ModalEntryPointPressableTrigger } from '@atlassian/jira-modal-entry-point-pressable-trigger/src/ModalEntryPointPressableTrigger.tsx';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { ContextualAnalyticsData } from '@atlassian/jira-product-analytics-bridge/src/utils/analytics-context-wrapper.tsx';
import type { editionAwarenessNextQuery } from '@atlassian/jira-relay/src/__generated__/editionAwarenessNextQuery.graphql';
import {
	PRODUCT_DISCOVERY,
	SERVICE_DESK,
	SOFTWARE,
} from '@atlassian/jira-shared-types/src/application-key.tsx';
import { FREE_EDITION, STANDARD_EDITION } from '@atlassian/jira-shared-types/src/edition.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { useProductEntitlementDetails } from '@atlassian/jira-tenant-context-controller/src/components/product-entitlement-details/index.tsx';
import type {
	BillingSourceSystem,
	SupportedApplicationEdition,
	SupportedApplicationKey,
	SupportedProjectType,
} from '../../common/types.tsx';
import { useShouldSeeButton } from '../../services/use-should-see-button.tsx';
import { handleUndefinedInObjectForAnalytics } from '../../common/utils.tsx';
import { editionAwarenessModalEntryPoint } from './entrypoint.tsx';
import messages from './messages.tsx';
import { getJiraApplicationKeyForProject } from './utils.tsx';

type SupportedTargetOffering = typeof STANDARD_EDITION;

export const ApplicationChargeElement: Record<SupportedApplicationKey, 'user' | 'agent'> = {
	[PRODUCT_DISCOVERY]: 'user',
	[SERVICE_DESK]: 'agent',
	[SOFTWARE]: 'user',
};

const TargetOfferings: Record<SupportedApplicationEdition, SupportedTargetOffering> = {
	[FREE_EDITION]: STANDARD_EDITION,
};

type EditionAwarenessButtonProps = {
	applicationEdition: SupportedApplicationEdition;
	applicationKey: SupportedApplicationKey;
	projectType: SupportedProjectType;
	siteHasJswOnly?: boolean;
};

const JPD_LIMITED_USERS_LEFT_LIMIT = 1;
const JSM_LIMITED_USERS_LEFT_LIMIT = 1;
const JSW_LIMITED_USERS_LEFT_LIMIT = 7;

type ButtonState = {
	message: MessageDescriptor;
	appearance: 'default' | 'warning' | 'danger';
};

const getButtonState = (
	applicationKey: SupportedApplicationKey,
	licensedUsers?: number | null,
	licensedUserLimit?: number,
): ButtonState => {
	if (!licensedUsers || licensedUsers <= 0 || !licensedUserLimit || licensedUserLimit <= 0) {
		return { message: messages.upgrade, appearance: 'default' };
	}

	if (applicationKey === PRODUCT_DISCOVERY) {
		if (licensedUsers >= licensedUserLimit) {
			return { message: messages.noCreatorsLeft, appearance: 'danger' };
		}
		if (licensedUsers > JPD_LIMITED_USERS_LEFT_LIMIT) {
			return { message: messages.limitedCreatorsLeft, appearance: 'warning' };
		}
	}

	if (applicationKey === SERVICE_DESK) {
		if (licensedUsers >= licensedUserLimit) {
			return { message: messages.noAgentsLeft, appearance: 'danger' };
		}
		if (licensedUsers > JSM_LIMITED_USERS_LEFT_LIMIT) {
			return { message: messages.limitedAgentsLeft, appearance: 'warning' };
		}
	}

	if (applicationKey === SOFTWARE) {
		if (licensedUsers >= licensedUserLimit) {
			return { message: messages.noUsersLeft, appearance: 'danger' };
		}

		if (licensedUsers >= JSW_LIMITED_USERS_LEFT_LIMIT) {
			return { message: messages.limitedUsersLeft, appearance: 'warning' };
		}
	}

	return { message: messages.upgrade, appearance: 'default' };
};

const MODAL_PROPS = {
	width: 968,
};

export const EditionAwarenessButton = (props: EditionAwarenessButtonProps) => {
	return (
		<JSErrorBoundary
			id="EditionAwarenessButton"
			packageName="edition-awareness"
			fallback="unmount"
			teamName="growth-tako"
		>
			<Placeholder name="edition-awareness-button-placeholder" fallback={null}>
				<EditionAwarenessWithContext {...props} />
			</Placeholder>
		</JSErrorBoundary>
	);
};

export const EditionAwarenessWithContext = (props: EditionAwarenessButtonProps) => {
	const { applicationKey, projectType, siteHasJswOnly } = props;

	// We are checking this requisites on the parent, but we are just adding it as a safeguard to avoid the API to be called for this invalid use case
	if (projectType === CORE_PROJECT && (applicationKey !== 'jira-software' || !siteHasJswOnly)) {
		throw new Error('CORE_PROJECT is only supported for Jira Software only sites');
	}

	const productEntitlementDetails = useProductEntitlementDetails();
	const billingSourceSystem =
		productEntitlementDetails?.[applicationKey]?.billingSourceSystem === 'CCP' ? 'CCP' : 'HAMS';

	return (
		<ContextualAnalyticsData
			attributes={{
				billingSourceSystem,
				applicationKey,
			}}
		>
			<EditionAwarenessButtonWithQuery {...props} billingSourceSystem={billingSourceSystem} />
		</ContextualAnalyticsData>
	);
};

type EditionAwarenessButtonWithBillingSystemProps = EditionAwarenessButtonProps & {
	billingSourceSystem: BillingSourceSystem;
};

const EditionAwarenessButtonWithQuery = (props: EditionAwarenessButtonWithBillingSystemProps) => {
	const { applicationEdition, applicationKey, projectType } = props;

	const { createAnalyticsEvent } = useAnalyticsEvents();

	const cloudId = useCloudId();
	const chargeElement = ApplicationChargeElement[applicationKey];
	const offeringKey = getProductOfferingKeyOrThrow(
		applicationKey,
		TargetOfferings[applicationEdition],
	);

	const data = useLazyLoadQuery<editionAwarenessNextQuery>(
		graphql`
			query editionAwarenessNextQuery(
				$cloudId: ID!
				$hamsProductKey: String!
				$chargeElement: String!
				$offeringKey: ID
			) {
				tenantContexts(cloudIds: [$cloudId])
					@optIn(to: ["CcpAllUserUpgradeAndPay", "HamsAllUserUpgradeAndPay"]) {
					entitlementInfo(hamsProductKey: $hamsProductKey) {
						entitlement {
							experienceCapabilities {
								changeOfferingV2(offeringKey: $offeringKey) {
									experienceUrl
									isAvailableToUser
								}
							}
							latestUsageForChargeElement(chargeElement: $chargeElement)
						}
					}
				}
			}
		`,
		{
			cloudId,
			hamsProductKey: applicationKey,
			chargeElement,
			offeringKey,
		},
	);

	const entitlement = data.tenantContexts?.[0]?.entitlementInfo?.entitlement;

	const licensedUsers = entitlement?.latestUsageForChargeElement;
	const productEntitlementDetails = useProductEntitlementDetails();
	const licensedUserLimit = productEntitlementDetails?.[applicationKey]?.unitCount;

	const isAvailableToUser =
		entitlement?.experienceCapabilities?.changeOfferingV2?.isAvailableToUser;
	const upgradeUrl = entitlement?.experienceCapabilities?.changeOfferingV2?.experienceUrl;
	const canUpgrade = Boolean(isAvailableToUser && upgradeUrl);

	useEffect(() => {
		// licensedUserLimit is not fetch from the API, but is still required information and we want to fire an error if it's not available
		if (
			isAvailableToUser === null ||
			isAvailableToUser === undefined ||
			(isAvailableToUser === true && !upgradeUrl) ||
			!licensedUsers ||
			licensedUsers <= 0 ||
			!licensedUserLimit ||
			licensedUserLimit <= 0
		) {
			// Creating the event will add the analytics context attributes
			const event = createAnalyticsEvent({});
			fireErrorAnalytics({
				event,
				meta: {
					id: 'editionAwarenessNextQuery',
					packageName: 'edition-awareness',
					teamName: 'growth-tako',
				},
				attributes: handleUndefinedInObjectForAnalytics({
					isAvailableToUser,
					hasUpgradeUrl: Boolean(upgradeUrl),
					licensedUsers,
					licensedUserLimit,
				}),
				sendToPrivacyUnsafeSplunk: true,
			});
		}
	}, [createAnalyticsEvent, isAvailableToUser, licensedUserLimit, licensedUsers, upgradeUrl]);

	const shouldSeeButton = useShouldSeeButton({
		applicationEdition,
		applicationKey,
		canUpgrade,
		projectType,
	});

	if (!shouldSeeButton) {
		return null;
	}

	const { message, appearance } = getButtonState(applicationKey, licensedUsers, licensedUserLimit);

	return (
		<ContextualAnalyticsData
			attributes={{
				appearance,
				licensedUsers,
				licensedUserLimit,
			}}
		>
			<EditionAwarenessButtonComponent
				{...props}
				message={message}
				appearance={appearance}
				offeringKey={offeringKey}
			/>
		</ContextualAnalyticsData>
	);
};

type EditionAwarenessButtonComponentProps = EditionAwarenessButtonWithBillingSystemProps &
	ButtonState & {
		offeringKey: string;
	};

const EditionAwarenessButtonComponent = (props: EditionAwarenessButtonComponentProps) => {
	const {
		applicationEdition,
		applicationKey,
		projectType,
		siteHasJswOnly,
		billingSourceSystem,
		message,
		appearance,
		offeringKey,
	} = props;

	const { formatMessage } = useIntl();
	const cloudId = useCloudId();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const handleOnClick = useCallback(() => {
		fireUIAnalytics(
			createAnalyticsEvent({
				action: 'clicked',
				actionSubject: 'button',
			}),
			'editionAwarenessButton',
		);
		fireUIAnalytics(
			createAnalyticsEvent({
				action: 'clicked',
				actionSubject: 'button',
			}),
			'editionAwarenessFreePill',
		);
	}, [createAnalyticsEvent]);

	useEffect(() => {
		fireUIAnalytics(
			createAnalyticsEvent({
				action: 'viewed',
				actionSubject: 'button',
			}),
			'editionAwarenessButton',
		);
		fireUIAnalytics(
			createAnalyticsEvent({
				action: 'viewed',
				actionSubject: 'button',
			}),
			'editionAwarenessFreePill',
		);
	}, [createAnalyticsEvent]);

	const entryPointProps = useMemo(
		() => ({
			applicationEdition,
			applicationKey,
			projectType,
			siteHasJswOnly,
			billingSourceSystem,
		}),
		[applicationEdition, applicationKey, projectType, siteHasJswOnly, billingSourceSystem],
	);

	const entryPointParams = useMemo(
		() => ({
			jiraApplicationKey: getJiraApplicationKeyForProject(projectType),
			cloudId,
			applicationKey,
			offeringKey: offeringKey ?? '',
			chargeElement: ApplicationChargeElement[applicationKey],
			projectType: convertToJiraProjectType(projectType),
			isAvatarsFGEnabled: fg('jira_ea_modal_avatars_gate'),
			isSkipTrialFGEnabled: applicationKey === SOFTWARE && fg('jsw_skip_trial_buy_now_v2'),
		}),
		[applicationKey, cloudId, offeringKey, projectType],
	);

	return (
		<ModalEntryPointPressableTrigger
			entryPoint={editionAwarenessModalEntryPoint}
			entryPointParams={entryPointParams}
			entryPointProps={entryPointProps}
			modalProps={MODAL_PROPS}
			interactionName="edition-awareness-modal"
			useInternalModal={false}
		>
			{({ ref }) => (
				<Button
					status={appearance}
					upgradeIconType="gem"
					// This is how the examples in and tests are setup
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					ref={ref as Ref<HTMLButtonElement>}
					onClick={handleOnClick}
				>
					{formatMessage(message)}
				</Button>
			)}
		</ModalEntryPointPressableTrigger>
	);
};
