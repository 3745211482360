import React, { forwardRef, useState, useRef, useLayoutEffect, useEffect } from 'react';
import { EngagementSpotlight } from '@atlassiansox/engagekit-ts';
import { useOverflowStatus } from '@atlaskit/atlassian-navigation';
import { SpotlightManager, SpotlightTarget } from '@atlaskit/onboarding';
import { Box, xcss } from '@atlaskit/primitives';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { useTopMenus } from '@atlassian/jira-navigation-apps-common/src/controllers/top-menus/index.tsx';
import { ADVANCED_ROADMAPS_TASK } from '@atlassian/jira-onboarding-quickstart-core/src/common/constants.tsx';
import { useShouldShowNudge } from '@atlassian/jira-onboarding-quickstart-core/src/services/index.tsx';
import { usePlansItems } from '@atlassian/jira-plans-item-store/src/index.tsx';
import { JIRA_SOFTWARE } from '@atlassian/jira-shared-types/src/application.tsx';
import { getEdition } from '@atlassian/jira-shared-types/src/edition.tsx';
import { useCanShowQuickstartNudge } from '@atlassian/jira-software-onboarding-nudges--next/src/controllers/quickstart-nudge/index.tsx';
import { NavigationPlansNavItemWrapperNudgeAsync } from '@atlassian/jira-software-onboarding-nudges--next/src/ui/navigation-plans/async.tsx';
import { useAppEditions } from '@atlassian/jira-tenant-context-controller/src/components/app-editions/index.tsx';
import { MenuTrigger as CommonMenuTrigger } from '../../../common/ui/menu/trigger/main.tsx';
import type { MenuTriggerProps } from '../../../common/ui/menu/trigger/types.tsx';
import { FullHeightTextColorWrapper } from '../../../common/ui/nudges/styled.tsx';

const WRAPPER_UID = 'plans-spotlight-target-wrapper';

const useDelayedSpotlight = () => {
	const [ready, setSpotlightReady] = useState(false);

	useLayoutEffect(() => {
		const timeoutId = setTimeout(() => setSpotlightReady(true), 100);
		return () => clearTimeout(timeoutId);
	}, []);

	return ready;
};

const useWrapper = () => {
	const ref = useRef<HTMLDivElement>(null);
	return {
		ref,
	};
};

const state = {
	hasSpotlightShown: false,
	hasSpotlightDone: false,
};
/**
 * The spotlight is forced closed on any click (outside of the menu), which is unexpected behavior
 * This hook will try to re-open the "More" dropdown when the "Plans" is unmounted until the user
 * clicks "Done" on the spotlight
 */
const useSpotlightForceOpen = () => {
	const { isVisible, openOverflowMenu } = useOverflowStatus();
	const isDropdownItem = !isVisible;
	useLayoutEffect(
		() => () => {
			const updateState = () => {
				/**
				 * The actual wrapper is supposed to be unmounted, so, if there is
				 * another wrapper element, it must be the cloned one on the spotlight
				 */

				// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
				const spotlightVisible = document.querySelector(`[data-uid="${WRAPPER_UID}"]`) != null;

				/**
				 * The dropdown menu is required to remaining openned after clicking Done on the
				 * spotlight. So openOverflowMenu() will be called until state.hasSpotlightDone is true.
				 */
				state.hasSpotlightShown = state.hasSpotlightShown || spotlightVisible;

				if (
					isDropdownItem &&
					spotlightVisible &&
					state.hasSpotlightShown &&
					!state.hasSpotlightDone
				) {
					openOverflowMenu();
				}

				if (state.hasSpotlightShown && !spotlightVisible) {
					state.hasSpotlightDone = true;
				}
			};
			queueMicrotask(updateState);
		},
		[isDropdownItem, openOverflowMenu],
	);
};

export const ConditionalNudgeWrapper = ({
	children,
	isVisible,
}: {
	children: JSX.Element;
	isVisible: boolean;
}) => {
	const shouldShowNudge = useCanShowQuickstartNudge();
	return shouldShowNudge && !__SERVER__ ? (
		<NavigationPlansNavItemWrapperNudgeAsync isInsideMenu={!isVisible}>
			<FullHeightTextColorWrapper>{children}</FullHeightTextColorWrapper>
		</NavigationPlansNavItemWrapperNudgeAsync>
	) : (
		<>{children}</>
	);
};

export const MenuTrigger = forwardRef<HTMLElement, MenuTriggerProps>((props, triggerRef) => {
	const wrapper = useWrapper();
	const ready = useDelayedSpotlight();
	const appEditions = useAppEditions();
	const jswEdition = appEditions ? getEdition(JIRA_SOFTWARE, appEditions) : undefined;

	const [shouldShow] = useShouldShowNudge({
		itemNames: [ADVANCED_ROADMAPS_TASK],
	});

	useSpotlightForceOpen();
	const { isVisible } = useOverflowStatus();

	const [{ items }] = usePlansItems();

	const analyticsAttributes = {
		isAbleToCreateSamplePlan: !!items?.links?.createSamplePlan?.isVisible,
		...(jswEdition ? { jswEdition } : {}),
	};

	const [isMenuOpen, { toggle: toggleMenu }] = useTopMenus(MENU_ID.PLANS);

	useEffect(() => {
		if (shouldShow && !isMenuOpen) {
			toggleMenu();
		}
	}, [isMenuOpen, shouldShow, toggleMenu]);

	return (
		<Box backgroundColor="color.background.neutral.subtle" xcss={triggerWrapperStyles}>
			<SpotlightManager>
				<SpotlightTarget name="nav-advanced-roadmaps-plans-spotlight">
					<Box
						data-uid={WRAPPER_UID}
						ref={wrapper.ref}
						backgroundColor="color.background.neutral.subtle"
						xcss={triggerWrapperStyles}
					>
						<ConditionalNudgeWrapper isVisible={isVisible}>
							<CommonMenuTrigger
								ref={triggerRef}
								analyticsAttributes={analyticsAttributes}
								{...props}
							/>
						</ConditionalNudgeWrapper>
					</Box>
				</SpotlightTarget>
				{ready && <EngagementSpotlight engagementId="nav-advanced-roadmaps-plans-spotlight" />}
			</SpotlightManager>
		</Box>
	);
});

const triggerWrapperStyles = xcss({
	height: '100%',
	display: 'flex',
	flexGrow: 1,
});
