import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	customLogoAltText: {
		defaultMessage: 'Custom Jira logo',
		id: 'atlassian-navigation.product-home.custom-logo-alt-text',
		description: 'Alt text for the custom logo',
	},
	customIconAltText: {
		defaultMessage: 'Custom Jira icon',
		id: 'atlassian-navigation.product-home.custom-icon-alt-text',
		description: 'Alt text for the custom icon',
	},
	jiraLogoLabel: {
		defaultMessage: 'Go to your Jira homepage',
		id: 'atlassian-navigation.product-home.jira-logo-label',
		description: 'Label for the Jira logo',
	},
	jsmLogoLabel: {
		defaultMessage: 'Go to your Jira Service Management homepage',
		id: 'atlassian-navigation.product-home.jsm-logo-label',
		description: 'Label for the Jira Service Management logo',
	},
	jpdLogoLabel: {
		defaultMessage: 'Go to your Jira Product Discovery homepage',
		id: 'atlassian-navigation.product-home.jpd-logo-label',
		description: 'Label for the Jira Product Discovery logo',
	},
	jcsLogoLabel: {
		defaultMessage: 'Go to your Jira Customer Service homepage',
		id: 'atlassian-navigation.product-home.jcs-logo-label',
		description: 'Label for the Jira Customer Service logo',
	},
});
