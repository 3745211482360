import getExplicitlyLicensedProducts from '@atlassian/jira-common-get-explicitly-licensed-products/src/index.tsx';
import { SOFTWARE } from '@atlassian/jira-shared-types/src/application-key.tsx';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';

/**
 * Checks whether a user only has Jira (JSW) explicitly licensed on their instance.
 */
export function useSiteHasOnlyJira() {
	const tenantContext = useTenantContext();

	try {
		const [firstExplicitlyLicensedProduct, ...rest] = getExplicitlyLicensedProducts(tenantContext);
		return rest.length === 0 && firstExplicitlyLicensedProduct === SOFTWARE;
	} catch {
		return false;
	}
}
